import { useEffect, useState } from 'react'

import { useStores } from 'models'

export function useUpdateComments(start?: Date, end?: Date): (boolean | null)[] {
  const { comments } = useStores()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  // Workaround for https://github.com/facebook/react/issues/20204
  const _start = start?.getTime()
  const _end = end?.getTime()

  useEffect(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        await comments.loadComments(start, end)
      } catch (e) {
        setError(e)
      } finally {
        setIsLoading(false)
      }
    }
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_end, _start])

  return [isLoading, error]
}
