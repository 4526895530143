import { useTranslation } from 'react-i18next'
import { faLaughBeam } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { BookingIcon } from 'components/booking-icon'
import { EmptyState } from 'components/empty-state'
import { List } from 'components/list'
import { Skeleton } from 'components/skeleton'
import { useStores } from 'models'
import { formatShortISO } from 'utils'

import {
  DashboardItem,
  DashboardItemCardHeader,
  DashboardItemTitle,
  InboxList,
  NumberOfBookingsInInbox,
  StyledDate,
} from './dashboard.styles'
import { Loading } from './loading'

/**
 * The number of bookings to show in the inbox on Dashboard
 *
 * NOTE: This could be a setting in future versions
 */
const bookingsToShowInInbox = 5

export const UnassignedBookings = observer(
  (): JSX.Element => {
    const { t } = useTranslation('dashboard')
    const { bookings: bookingStore, stations, workers, ui } = useStores()

    const bookings = bookingStore.inbox(ui.selectedStation?.id)
    const numberOfBookings = bookings.length > bookingsToShowInInbox ? bookingsToShowInInbox : bookings.length
    const totalBookings = bookingStore.inbox().length
    const isLoading = stations.isLoading || workers.isLoading

    return (
      <DashboardItem>
        <DashboardItemCardHeader>
          <DashboardItemTitle>{t('unassignedBookings')}</DashboardItemTitle>
        </DashboardItemCardHeader>
        {isLoading ? (
          <>
            <Loading />
            <NumberOfBookingsInInbox>
              <Skeleton width="large" />
            </NumberOfBookingsInInbox>
          </>
        ) : totalBookings > 0 ? (
          <>
            <InboxList>
              {bookings.slice(0, bookingsToShowInInbox).map(booking => {
                return (
                  <List.Item key={booking.id}>
                    <List.Item.Left>
                      <BookingIcon size="2x" />
                    </List.Item.Left>
                    <List.Item.Title>
                      <StyledDate>{formatShortISO(booking.createdAt)}</StyledDate>
                      {booking.bookingTitle}
                    </List.Item.Title>
                    <List.Item.Description>{booking.description}</List.Item.Description>
                  </List.Item>
                )
              })}
            </InboxList>
            <NumberOfBookingsInInbox>
              {t('showingNumberOfBookings', {
                numberOfBookings,
                totalBookings,
              })}
            </NumberOfBookingsInInbox>
          </>
        ) : (
          <EmptyState icon={<FontAwesomeIcon icon={faLaughBeam} />} title={t('emptyInbox')} />
        )}
      </DashboardItem>
    )
  },
)
