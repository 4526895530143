import { WorkerDto, WorkerStationInfoDto, WorkerUnavailableDto } from '@cdab/cplan-api-client'
import { AxiosResponse } from 'axios'
import { parseISO } from 'date-fns'
import { applySnapshot, flow, getRoot, getSnapshot, Instance, types } from 'mobx-state-tree'

import { IRootStore } from 'models/rootStore'

import { withEnvironment } from '../extensions'

import { Unavailable } from './unavailable'
import { IWorker, Worker } from './worker'

export const WorkersStore = types
  .model('WorkersStore', {
    byId: types.map(Worker),
    isLoading: false,
  })
  .extend(withEnvironment)
  .views(self => {
    return {
      /**
       * get a worker with given id from store
       * @param id - id of worker
       */
      get(id: string): IWorker {
        const worker = self.byId.get(id)
        if (!worker) throw new Error(`Worker with id '${id}' does not exist in store`)
        return worker
      },
      /**
       * Get all workers in the store
       *
       * @param sorted Defines if the list should be sorted by name
       */
      allWorkers(sorted = true): IWorker[] {
        const workers = Array.from(self.byId.values())

        if (!sorted) return workers

        return workers.sort((worker1, worker2) => {
          if (worker1.name < worker2.name) return -1
          else if (worker1.name > worker2.name) return 1
          else return 0
        })
      },
      /**
       *  Get workers from store from a list with IDs
       *
       * @param workerIDs list with IDs
       * @param silent
       * @param sorted Sort the workers in alphabetical order
       */
      fromIds(workerIDs: string[], silent = false, sorted = true): IWorker[] {
        const workers = workerIDs.reduce<IWorker[]>((acc, curr): IWorker[] => {
          try {
            return [this.get(curr), ...acc]
          } catch (e) {
            if (silent) return acc
            else throw e
          }
        }, [])

        if (!sorted) return workers

        return workers.sort((worker1, worker2) => {
          if (worker1.name < worker2.name) return -1
          else if (worker1.name > worker2.name) return 1
          else return 0
        })
      },
      /**
       * get workers from selectedStation in localStorage
       */
      get workersFromCurrentStation(): IWorker[] {
        const selectedStation = getRoot<IRootStore>(self).ui.selectedStation?.id

        if (selectedStation === undefined) throw new Error(`Selected Station is not set in localStorage`)

        const workersIds: string[] = getRoot<IRootStore>(self).stations.get(selectedStation).workers
        const workers = this.fromIds(workersIds)

        return workers
      },
    }
  })
  .actions(self => {
    const addWorker = (
      id: string,
      name: string,
      unavailables: WorkerUnavailableDto[],
      stationInfo: WorkerStationInfoDto[],
    ): IWorker => {
      const worker = Worker.create({
        id,
        name,
      })

      unavailables.forEach(item => {
        const id = item.id?.toString() || Math.random().toString()
        const workerId = item.workerId || Math.random()
        const start = item.start ? new Date(item.start) : new Date()
        const end = item.end ? new Date(item.end) : new Date()

        const unavailableData = Unavailable.create({
          id,
          workerId,
          start,
          end,
        })

        worker.addUnavailable(unavailableData)
      })

      // Update worker if already exists in store
      if (self.byId.has(id)) {
        const originalWorker = self.byId.get(id) as IWorker
        const data = getSnapshot(worker)

        applySnapshot(originalWorker, data)
      } else {
        // Create new worker
        self.byId.set(id, worker)
      }

      const { stationWorkers } = getRoot<IRootStore>(self)
      stationInfo.forEach(dto => {
        if (!dto.stationId) return

        stationWorkers.AssignWorkerToStation({
          stationId: dto.stationId.toString(),
          workerId: id,
          deleted: !!dto.deleted,
          lastBookingDate: dto.lastPlannedBooking ? parseISO(dto.lastPlannedBooking) : null,
        })
      })

      return worker
    }

    /**
     * loadFromServer loads workers with given ids from server and adds to store
     */
    const loadFromServer = flow(function* (workerIds: string[]) {
      if (workerIds.length <= 0) return

      self.isLoading = true
      const params = new URLSearchParams()
      workerIds.forEach(id => params.append('workerIds', id))

      try {
        const { data: workerData } = (yield self.environment.api.workers.getWorkers(
          workerIds.map(Number),
        )) as AxiosResponse<WorkerDto[]>

        workerData.forEach(worker => {
          if (worker.id === undefined) throw new Error(`Received worker without ID from server`)

          const { id, name, unavailable } = worker

          addWorker(id.toString(), name, unavailable || [], worker.stationInfo || [])
        })
      } finally {
        self.isLoading = false
      }
    })

    return {
      addWorker,
      loadFromServer,
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWorkersStore extends Instance<typeof WorkersStore> {}
