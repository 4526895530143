import { SetWorkerUnavailableDto, WorkerUnavailableDto } from '@cdab/cplan-api-client'
import { AxiosResponse } from 'axios'
import { areIntervalsOverlapping, endOfDay, formatISO, startOfDay } from 'date-fns'
import fp from 'lodash/fp'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import { withEnvironment } from 'models/extensions'

import { IBooking } from '../bookingStore'
import { IRootStore } from '../rootStore'

import { IUnavailable, Unavailable } from './unavailable'

export const Worker = types
  .model('Worker', {
    id: types.identifier,
    name: types.string,
    unavailable: types.map(Unavailable),
  })
  .extend(withEnvironment)
  .actions(self => {
    const addUnavailable = (unavailable: IUnavailable): void => {
      self.unavailable.set(unavailable.id, unavailable)
    }

    /**
     * Set a as unavailable during the given period
     */
    const setUnavailable = flow(function* (start: Date, end: Date) {
      const data: SetWorkerUnavailableDto = {
        start: formatISO(start),
        end: formatISO(end),
      }

      try {
        const res: AxiosResponse<WorkerUnavailableDto[]> = yield self.environment.api.workers.setUnavailable(
          Number(self.id),
          data,
        )

        res.data.forEach((item: WorkerUnavailableDto) => {
          // ! TODO: Justera datatyperna
          const data: IUnavailable = {
            workerId: item.workerId || 0,
            id: item.id?.toString() || '0',
            start: new Date(item.start || 0),
            end: new Date(item.end || 0),
          }

          self.unavailable.set(item.id?.toString() || '0', data)
        })
        // eslint-disable-next-line no-empty
      } finally {
      }
    })

    /**
     * Set worker available again, by removing a previously set unavailable period
     */
    const setAvailable = flow(function* (unavailableId: number) {
      try {
        yield self.environment.api.workers.removeUnavailable(unavailableId)
        self.unavailable.delete(unavailableId.toString())
      } finally {
        // self.isLoading = false
      }
    })

    return {
      addUnavailable,
      setAvailable,
      setUnavailable,
    }
  })
  .views(self => {
    return {
      get isUnavailable(): boolean {
        return self.unavailable.size > 0
      },

      /**
       * get all bookings in store for this worker at specified date
       * @param date - date for bookings
       */
      bookingsAtDate(date: Date, stationId?: string): IBooking[] {
        const { assignments } = getRoot<IRootStore>(self)

        const bookings: IBooking[] = assignments.ByWorkerId(self.id).map(assignment => assignment.Booking)

        const interval = {
          start: startOfDay(date),
          end: endOfDay(date),
        }

        const filteredBookings = fp.flow(
          fp.filter((booking: IBooking) => booking.startTime !== null),
          fp.filter(({ startTime, endTime }: IBooking) => {
            if (startTime === null || endTime === null)
              throw new Error(`bookingsAtDate: booking with no start time (should not happend here)`)
            return areIntervalsOverlapping(interval, {
              start: startTime,
              end: endTime,
            })
          }),
          fp.filter((b: IBooking) => (stationId !== undefined ? stationId === b.stationId : true)),
        )(bookings)
        return filteredBookings
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWorker extends Instance<typeof Worker> {}
