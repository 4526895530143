import { PropsWithChildren } from 'react'

import { Heading } from '../typography/heading'

import { IFormSectionProps } from './form-section.interfaces'
import { Body, Header, StyledFormSection } from './form-section.styles'
import { formSectionTheme } from './form-section.theme'

export const FormSection = ({
  border = false,
  icon,
  title,
  children,
  className,
}: PropsWithChildren<IFormSectionProps>): JSX.Element => {
  return (
    <StyledFormSection border={border} className={className}>
      {(icon || title) && (
        <Header>
          {icon && icon}
          {title && <Heading>{title}</Heading>}
        </Header>
      )}
      <Body>{children}</Body>
    </StyledFormSection>
  )
}

FormSection.defaultTheme = formSectionTheme
