import React, { CSSProperties } from 'react'
import { faCheckCircle, faExclamationTriangle, faSyncAlt } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getHours, getMinutes } from 'date-fns'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'

import { useStores } from 'models'

import { ITaskProps } from './scheduledtask.interfaces'
import {
  BadgeCircle,
  Badges,
  BadgeText,
  InfoLine,
  LoadingOverlay,
  StyledTaskBase,
  TaskStartTime,
  TaskTitle,
} from './scheduledtask.styles'

const floatRightStyle: CSSProperties = { float: 'right' }

const Badge: React.FC = function ({ children }) {
  return (
    <BadgeCircle>
      <BadgeText>{children}</BadgeText>
    </BadgeCircle>
  )
}

export const Task = observer(function Task({ booking, isDragging, worker }: ITaskProps) {
  const { attributes, assignments } = useStores()

  const spin = !!booking.workStarted && !booking.workFinished
  const icon = booking.isDelayed
    ? faExclamationTriangle
    : booking.workStarted && !booking.workFinished
    ? faSyncAlt
    : booking.workFinished
    ? faCheckCircle
    : false

  const bookingMinutes = getMinutes(booking.startTime || new Date())
  const displayMinutes = bookingMinutes.toString().padStart(2, '0')
  const bookingHours = getHours(booking.startTime || new Date())
  const displayHours = bookingHours.toString().padStart(2, '0')

  const entries = Array.from(booking.attributes.entries())
  const booleanAttributes = _.reduce(
    entries,
    (acc, [typeId, value]): { id: string; shortcode: string }[] => {
      const attributeType = attributes.attributes.get(typeId)
      return attributeType && attributeType.isBoolean && value.toLocaleLowerCase().trim() === 'true'
        ? [...acc, { id: typeId, shortcode: attributeType.shortcode || attributeType.name[0] }]
        : acc
    },
    [] as { id: string; shortcode: string }[],
  )

  const isShadowTask = !!(worker && assignments.GetUnique(worker.id, booking.id)?.isShadowBooking)
  return (
    <StyledTaskBase
      isDelayed={booking.isDelayed}
      isDragging={isDragging}
      isFinished={booking.workFinished}
      isOngoing={booking.isOngoing}
      isShadowTask={isShadowTask}
    >
      <LoadingOverlay isLoading={booking.isLoading}></LoadingOverlay>
      <TaskTitle>
        {icon && <FontAwesomeIcon icon={icon} size="sm" spin={spin} style={floatRightStyle} />}
        {booking.bookingTitle}
      </TaskTitle>
      <InfoLine>
        <TaskStartTime>
          {displayHours}:{displayMinutes} - {booking.description}
        </TaskStartTime>
        <Badges>
          {booleanAttributes.map(({ id, shortcode }) => (
            <Badge key={id}>{shortcode}</Badge>
          ))}
          {booking.status && <Badge>{booking.status.shortcode}</Badge>}
        </Badges>
      </InfoLine>
    </StyledTaskBase>
  )
})
