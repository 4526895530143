/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'

import { DomEventHandler, snapNumber, useDomEvent, useIsMounted, useThrottleOnAnimationFrame } from 'utils'

import { useDragBooking } from './hooks'
import { IScheduledTaskProps } from './scheduledtask.interfaces'
import { StyledResizeHandle, TaskInLane } from './scheduledtask.styles'
import { Task } from './task'

/**
 * ScheduledTask represents a task scheduled on a worker
 */
export const ScheduledTask = observer(
  ({
    booking,
    worker,
    pixelTimeRatio,
    onEditBooking,
    onResizeEnd,
    right,
    originX,
    left,
    lane,
    maxWidth,
  }: IScheduledTaskProps): JSX.Element | null => {
    const [diffX, setDiffX] = useState(0)
    // NOTE: Both ref and state is needed for isRezising, as it's used with different timing
    const [isResizing, setIsResizing] = useState(false)
    const isResizingRef = useRef(false)
    const { dragRef, isDragging } = useDragBooking({ booking, worker }, !isResizing)

    const isMounted = useIsMounted()

    const onClick = useCallback(() => onEditBooking(booking), [booking, onEditBooking])

    /**
     * callback that resize the task visually, but throttle it to at most 1/10s
     */
    const throttledMouseMoveHandler = useThrottleOnAnimationFrame((e: MouseEvent) => {
      if (!isResizingRef.current) return

      const diff = snapNumber(e.clientX - right - originX, 15 * pixelTimeRatio)
      setDiffX(diff)
    })
    useDomEvent('mousemove', throttledMouseMoveHandler, isResizing)

    // call onResizeEnd callback when resizing is done
    useDomEvent(
      'mouseup',
      (e: MouseEvent) => {
        isResizingRef.current = false
        setDiffX(0)
        onResizeEnd && onResizeEnd(booking, e.clientX - right - originX)

        // quit resizing after a delay so it's fired after the onClick event on the
        // element. This is to prevent the onClick callback if we were resizing
        booking.unlock()
        setTimeout(() => isMounted && setIsResizing(false), 0)
      },
      isResizing,
    )

    const startResize = useCallback(() => {
      if (booking.isLoading) return
      booking.lock()
      isResizingRef.current = true
      setIsResizing(true)
    }, [booking])

    const handleOnClickBooking = useCallback(() => {
      !isResizing && onClick()
    }, [isResizing, onClick])

    if ((left < 0 && right < 0) || (left > maxWidth && right > maxWidth)) {
      return null
    }

    return (
      <TaskInLane
        ref={dragRef}
        lane={lane}
        left={left}
        maxWidth={maxWidth}
        right={right + diffX}
        onClick={handleOnClickBooking}
      >
        <Task booking={booking} isDragging={isDragging} worker={worker} />
        {!booking.workFinished && <StyledResizeHandle onMouseDown={startResize} />}
      </TaskInLane>
    )
  },
)
