import { observer } from 'mobx-react-lite'

import { useDragBooking } from './hooks'
import { IDayTaskProps } from './scheduledtask.interfaces'
import { StyledDayTask } from './scheduledtask.styles'
import { Task } from './task'

export const DayTask = observer(function DayTask({ booking }: IDayTaskProps) {
  const { dragRef, isDragging } = useDragBooking({ booking }, true)

  return (
    <StyledDayTask ref={dragRef}>
      <Task booking={booking} isDragging={isDragging} />
    </StyledDayTask>
  )
})
