import styled from 'styled-components/macro'

export const StyledDaySchedule = styled.div`
  //padding: 1rem;
`

export const BookingContainer = styled.div<{ isOver: boolean }>`
  background: ${({ theme, isOver }) =>
    isOver ? theme.colors.BACKGROUND_BASIC_COLOR_2 : theme.colors.BACKGROUND_BASIC_COLOR_4};
  min-height: 2rem;

  display: flex;
  flex-wrap: wrap;

  & > div {
    margin: 0.1rem;
  }
`

export const StyledBooking = styled.div``

export const DayScheduleTitle = styled.div``
