import { Alert } from 'components/alert'
import { Calendar } from 'components/calendar'
import { Card } from 'components/card/card'
import { Chip } from 'components/chip'
import { DatePicker } from 'components/date-picker/date-picker'
import { DialogBox } from 'components/dialogbox'
import { EmptyState } from 'components/empty-state'
import { FormSection } from 'components/form-section'
import { Button } from 'components/inputs/button'
import { Checkbox } from 'components/inputs/checkbox'
import { CircleButton } from 'components/inputs/circle-button'
import { InputWrapper } from 'components/inputs/input-wrapper/input-wrapper'
import { MultiSelect } from 'components/inputs/multiselect'
import { TextInput } from 'components/inputs/text-input'
import { Grid } from 'components/layout/grid'
import { Module } from 'components/layout/module'
import { Page } from 'components/layout/page'
import { Sidebar } from 'components/layout/sidebar/sidebar'
import { SidebarModule } from 'components/layout/sidebar/sidebarmodule'
import { Toolbar } from 'components/layout/toolbar'
import { ToolbarItem } from 'components/layout/toolbar/toolbar-item'
import { ListDescription } from 'components/list/listdescription/listdescription'
import { ListExtraLeft } from 'components/list/listextraleft/listextraleft'
import { ListExtraRight } from 'components/list/listextraright/listextraright'
import { ListTitle } from 'components/list/listtitle/listtitle'
import { Logo } from 'components/logo'
import { NavBar } from 'components/navbar/navbar'
import { NavItem } from 'components/navbar/navitem'
import { Notification } from 'components/notification'
import { Skeleton } from 'components/skeleton'
import { createTheme } from 'components/theming'
import { TCustomBaseColors } from 'components/theming/basecolors'
import { TCustomColors } from 'components/theming/colors'
import { TCustomSupport } from 'components/theming/support'
import { TCustomTypography } from 'components/theming/typography'
import { Caption } from 'components/typography/caption'
import { Heading } from 'components/typography/heading'
import { Label } from 'components/typography/label'
import { Paragraph } from 'components/typography/paragraph'

const baseColors: TCustomBaseColors = {}
const colors: TCustomColors = {}
const typography: TCustomTypography = {}
const support: TCustomSupport = {}

export const theme = createTheme(
  [
    Alert,
    Button,
    Caption,
    Calendar,
    Card,
    Chip,
    Checkbox,
    CircleButton,
    DatePicker,
    DialogBox,
    EmptyState,
    FormSection,
    Grid,
    Heading,
    InputWrapper,
    Label,
    ListDescription,
    ListExtraLeft,
    ListExtraRight,
    ListTitle,
    Logo,
    Module,
    MultiSelect,
    NavBar,
    NavItem,
    Notification,
    Page,
    Paragraph,
    Sidebar,
    SidebarModule,
    Skeleton,
    TextInput,
    Toolbar,
    ToolbarItem,
  ],
  {
    baseColors,
    colors,
    typography,
    support,
  },
)
