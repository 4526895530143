import { useCallback, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useParams } from 'react-router-dom'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  addDays,
  addMinutes,
  endOfDay,
  format,
  parseISO,
  roundToNearestMinutes,
  startOfDay,
  startOfWeek,
} from 'date-fns'
import { action, reaction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { parse } from 'query-string'

import { BookingModal } from 'components/booking-modal/booking-modal'
import { DaySchedule } from 'components/dayschedule'
import { Button } from 'components/inputs/button'
import { Page, PageContent } from 'components/layout/page'
import { ScheduleWeek } from 'components/schedule-week'
import { SecondaryControls } from 'components/secondary-controls'
import { WorkerSchedule } from 'components/workerschedule'
import i18n from 'config/i18n'
import { IBooking, IWorker, useStores } from 'models'
import { IWorkerStationAssignment } from 'models/workerStationStore'
/** Import interfaces here */
/** Import utilities here */
import { PATHS } from 'pages/approuter'
import { formatShortISO, getLocaleFile, snapNumber, useRouter, useScrollHashIntoView } from 'utils'
import { useUpdateComments } from 'utils/comments'

import { DateButton, DateNavigation, StyledSelect, StyledToolbar } from './schedule.styles'
import { ScheduleSidebar } from './schedulesidebar'

enum View {
  Day = 'day',
  Week = 'week',
}

interface LocationSearch {
  view?: View
}

const scheduleViewOptions = [
  { value: View.Day, label: i18n.t('common:day') },
  { value: View.Week, label: i18n.t('common:week') },
]

const useUpdateBookings = (date?: string): void => {
  const { bookings, ui } = useStores()
  const source = useLocalObservable(() => ({ date }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    action(() => {
      source.date = date
    }),
    [date],
  )

  const doUpdate = (data: { date?: string; stationId?: string }): void => {
    const { date, stationId } = data

    if (date === undefined || stationId === undefined) return

    const startDate = startOfWeek(new Date(date), { locale: getLocaleFile() })

    const endDate = new Date()
    endDate.setDate(startDate.getDate() + DAYS_TO_SHOW - 1)

    bookings.updateBookingsFromServer(parseISO(formatShortISO(startDate)), parseISO(formatShortISO(endDate)), {
      getInbox: true,
    })
  }

  useEffect(
    () =>
      reaction(
        () => ({
          date: source.date,
          stationId: ui.selectedStation?.id,
        }),
        doUpdate,
        { fireImmediately: true },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}

const DAYS_TO_SHOW = 7

export const SchedulePage = observer(function SchedulePage() {
  const { t } = useTranslation('common')
  const [scheduleView, setScheduleView] = useState<View>()
  const [idOfbookingToEdit, setIdOfBookingToEdit] = useState<string | null>(null)
  const { history } = useRouter()
  const { stationWorkers, workers, bookings, ui, organization } = useStores()
  const { date: dateParam } = useParams<{ date?: string }>()
  const location = useLocation()
  useUpdateBookings(dateParam)

  // Redirect to today, if no date is given
  useEffect(() => {
    if (dateParam === undefined) {
      const path = generatePath(PATHS.schedule.path, {
        date: formatShortISO(new Date(Date.now())),
      })
      history.push(path)
    }
  }, [dateParam, history])

  /**
   * Get URL params to decide which view should be active; day or view.
   * If value of `view` isn't supported, it falls back to default value.
   *
   * Defaults to `day`.
   */
  useEffect(() => {
    const search: LocationSearch = parse(location.search)
    const params = new URLSearchParams()

    // Default values
    if (!search.view) {
      // Set default view and update params
      setScheduleView(View.Day)
      params.set('view', View.Day)
    } else {
      if (!scheduleView) {
        setScheduleView(search.view)
        params.set('view', search.view)
      } else {
        if (scheduleView === View.Day) {
          params.set('view', View.Day)
        } else if (scheduleView === View.Week) {
          params.set('view', View.Week)
        }
      }
    }

    history.push({ search: params.toString() })
  }, [history, location.search, scheduleView])

  const date = useMemo(() => (dateParam ? parseISO(dateParam) : new Date(Date.now())), [dateParam])

  useScrollHashIntoView()

  useUpdateComments(startOfDay(date), endOfDay(date))

  // Link used for navigating to previous day
  const prevDayPath = date
    ? generatePath(PATHS.schedule.path, {
        date: formatShortISO(addDays(date, -1)),
      })
    : ''

  // link used for navigating to next day
  const nextDayPath = date
    ? generatePath(PATHS.schedule.path, {
        date: formatShortISO(addDays(date, 1)),
      })
    : ''

  const todayPath = useMemo(
    () =>
      generatePath(PATHS.schedule.path, {
        date: formatShortISO(new Date(Date.now())),
      }),
    [],
  )

  // Callback when a booking is moved between workers, or moved within a worker
  const rescheduleBooking = useCallback(
    (worker: IWorker, sourceWorkerId: string, bookingID: string, initialTime: number, time: number): void => {
      const booking = bookings.get(bookingID)
      if (booking.startTime === null) {
        throw new Error(`Booking without startTime cannot be REscheduled (It should scheduled first)`)
      }
      const differenceInMinutes = time - initialTime
      const newStartTime = roundToNearestMinutes(addMinutes(booking.startTime, differenceInMinutes), { nearestTo: 15 })

      bookings.moveBooking(bookingID, {
        toWorkerID: { assignedTo: worker.id, isShadowAssignment: false }, // When we move a booking, isShadowAssignment should be set to false always (per specification)
        newStartTime,
        fromWorkerID: sourceWorkerId,
      })
    },
    [bookings],
  )

  // Callback when a booking is moved from inbox to the schedule
  const scheduleBooking = useCallback(
    (worker: IWorker, bookingID: string, time: number): void => {
      const startTime = roundToNearestMinutes(addMinutes(startOfDay(date), time), { nearestTo: 15 })
      bookings.moveBooking(bookingID, {
        toWorkerID: { assignedTo: worker.id, isShadowAssignment: false }, // When we move a booking, isShadowAssignment should be set to false always (per specification)
        newStartTime: startTime,
      })
    },
    [bookings, date],
  )

  const onEditBooking = useCallback((booking: IBooking): void => {
    setIdOfBookingToEdit(booking.id)
  }, [])

  const onSelectDate = useCallback(
    (date: Date): void => {
      const formattedDatePath = format(date, 'yyyy-MM-dd')
      // We don't have to navigate if we're on the same date
      if (!window.location.href.includes(formattedDatePath)) history.push(`/schedule/${formattedDatePath}`)
    },
    [history],
  )

  const onCloseBookingModal = useCallback((): void => {
    if (idOfbookingToEdit) {
      const booking = bookings.bookings.get(idOfbookingToEdit)

      if (booking && booking.startTime) {
        onSelectDate(booking.startTime)
      }
    }

    setIdOfBookingToEdit(null)
  }, [bookings.bookings, idOfbookingToEdit, onSelectDate])

  const handleResizeBooking = useCallback(
    (booking: IBooking, newLength: number): void => {
      bookings.resizeBooking(booking.id, snapNumber(newLength))
    },
    [bookings],
  )

  const renderSidebar = useMemo(() => <ScheduleSidebar selectedDate={date} onSelectDate={onSelectDate} />, [
    date,
    onSelectDate,
  ])

  const onChange = (value: { value: View; label: string }) => {
    setScheduleView(value.value)
  }

  const renderToolbar = useCallback(() => {
    return (
      <StyledToolbar>
        <StyledSelect
          input={false}
          isSearchable={false}
          options={scheduleViewOptions}
          placeholder={t(`${scheduleView}`)}
          onChange={onChange}
        />
        <DateNavigation>
          <DateButton to={prevDayPath}>
            <Button>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          </DateButton>
          <DateButton to={todayPath}>
            <Button>{t('schedule:today')}</Button>
          </DateButton>
          <DateButton to={nextDayPath}>
            <Button>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </DateButton>
        </DateNavigation>
      </StyledToolbar>
    )
  }, [nextDayPath, prevDayPath, scheduleView, t, todayPath])

  if (!date) return null

  let workerSchedules: IWorkerStationAssignment[] = []
  if (ui.selectedStation) {
    workerSchedules = stationWorkers
      .byStationId(ui.selectedStation.id, date)
      .sort((ws1, ws2) => workers.get(ws1.workerId).name.localeCompare(workers.get(ws2.workerId).name)) // Hoping it's stable
      .sort(
        (ws1, ws2) =>
          ((workers.get(ws1.workerId).isUnavailable as unknown) as number) -
          ((workers.get(ws2.workerId).isUnavailable as unknown) as number),
      )
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <Page secondaryControlsComponent={<SecondaryControls />} sidebar={renderSidebar} title={t('common:schedule')}>
        <PageContent renderToolbar={renderToolbar} shadow={true} title={dateParam}>
          {ui.selectedStation === undefined ? (
            // TODO: Add placeholder bookings while loading, like Facebook and LinkedIn
            <div>{t('common:loading')}</div>
          ) : scheduleView === 'day' ? (
            <>
              <DaySchedule />
              {workerSchedules.map(ws => (
                <WorkerSchedule
                  key={ws.workerId}
                  date={date}
                  deleted={ws.deleted}
                  endTime={organization.workdayEndtime}
                  startTime={organization.workdayStartime}
                  worker={workers.get(ws.workerId)}
                  onDropBooking={rescheduleBooking}
                  onDropFromInbox={scheduleBooking}
                  onEditBooking={onEditBooking}
                  onResizeBooking={handleResizeBooking}
                />
              ))}
            </>
          ) : (
            <ScheduleWeek selectedDate={date} />
          )}
        </PageContent>
        {idOfbookingToEdit && <BookingModal bookingId={idOfbookingToEdit} onClose={onCloseBookingModal} />}
      </Page>
    </DndProvider>
  )
})
