import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { Alert } from 'components/alert'
import { Card } from 'components/card'
import { Button } from 'components/inputs/button'
import { InputWrapper } from 'components/inputs/input-wrapper'
import { TextInput } from 'components/inputs/text-input'

import { ILoginFormProps, TFormData } from './loginform.interfaces'
import { ForgotPasswordText, StyledForm, Title } from './loginform.styles'
import { LoginSchema } from './loginform.validation'

export const LoginForm = ({ errorMessage, onSubmit }: ILoginFormProps): JSX.Element => {
  const { t } = useTranslation('login')
  const { register, handleSubmit, formState, errors } = useForm<TFormData>({
    resolver: yupResolver(LoginSchema),
    mode: 'onBlur',
  })

  const onHandleSubmit = ({ email, password }: TFormData): void => {
    onSubmit(email, password)
  }

  return (
    <Card shadow={true}>
      <Title>{t('signInToYourAccount')}</Title>
      <StyledForm onSubmit={handleSubmit(onHandleSubmit)}>
        <InputWrapper direction="vertical">
          <TextInput
            autoComplete="username"
            caption={errors.email?.message}
            fullWidth={true}
            inputRef={register}
            label={t('common:email')}
            name="email"
            status={errors.email ? 'danger' : 'basic'}
          />
          <TextInput
            autoComplete="current-password"
            caption={errors.password?.message}
            fullWidth={true}
            inputRef={register}
            inputType="password"
            label={t('common:password')}
            name="password"
            status={errors.password ? 'danger' : 'basic'}
          />
        </InputWrapper>
        <ForgotPasswordText to="google.com">{t('forgotPassword')}</ForgotPasswordText>
        <Button appearance="filled" disabled={formState.isSubmitting} status="primary" type="submit">
          {formState.isSubmitting ? t('loading') : t('login')}
        </Button>
        {!!errorMessage && <Alert message={errorMessage} status="danger" />}
      </StyledForm>
    </Card>
  )
}
