import { useState } from 'react'
import useResizeAware from 'react-resize-aware'
import { animated, config, useSpring } from 'react-spring'

import { ISidebarModuleProps } from './sidebarmodule.interfaces'
import { Content, StyledHeader, StyledSidebarModule, Title } from './sidebarmodule.styles'
import { sidebarModuleTheme } from './sidebarmodule.theme'

export const SidebarModule = ({ children, footer, contentPadding, header }: ISidebarModuleProps): JSX.Element => {
  const [isClosed, toggleClosed] = useState(false)
  const [resizeListener, { height }] = useResizeAware()

  const animProps = useSpring({
    config: config.gentle,
    height: isClosed ? 0 : height || 1,
    opacity: isClosed ? 0 : 1,
  })

  function onClick(): void {
    return toggleClosed(!isClosed)
  }

  return (
    <StyledSidebarModule footer={footer} shadow={true} spacing="none">
      {/* FIXME: Justera så att Header inte renderas om title är undefined */}
      <StyledHeader onClick={onClick}>{header && <Title>{header}</Title>}</StyledHeader>

      <animated.div style={{ ...animProps }}>
        <Content contentPadding={contentPadding}>
          {resizeListener}
          {children}
        </Content>
      </animated.div>
    </StyledSidebarModule>
  )
}

SidebarModule.defaultTheme = sidebarModuleTheme
