import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Card } from 'components/card'
import { Button } from 'components/inputs/button'
import { TextInput } from 'components/inputs/text-input'
import { useStores } from 'models'
import { PATHS } from 'pages/approuter'
import { useRouter } from 'utils'

export const SignupPage: React.FunctionComponent = () => {
  const { push } = useRouter()
  const { userStore } = useStores()

  useEffect(() => {
    if (userStore.isAuthenticated) {
      push(PATHS.dashboard.path)
    }
  }, [push, userStore.isAuthenticated])

  return (
    <Card>
      <h2>Sign up</h2>
      <form>
        <TextInput inputType="email" placeholder="email" />
        <TextInput inputType="password" placeholder="password" />
        <TextInput inputType="password" placeholder="password again" />
        <Button>Sign Up</Button>
      </form>
      <Link to={PATHS.login.path}>Already have an account?</Link>
    </Card>
  )
}

export default SignupPage
