import { Link } from 'react-router-dom'
import ReactSelect from 'react-select'
import styled from 'styled-components/macro'

const Select = styled(ReactSelect)`
  // This is required to make ReactSelect to flow with flex
  [class*='-placeholder'],
  [class*='-singleValue'] {
    position: static;
    transform: none;
    position: static;
    top: auto;
    left: auto;
    transform: none;
    max-width: none;
  }
`

export const StyledSelect = (props): JSX.Element => <Select {...props} />

export const StyledToolbar = styled.div`
  display: flex;
  align-items: center;

  > div > div > div > div::first-of-type {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    max-width: none;
  }
`

export const DateNavigation = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-items: center;
  grid-column-gap: 1rem;
  margin-left: 1rem;
`

export const DateButton = styled(Link)`
  border: none;
  text-decoration: none;
`
