import { Card, Grid, Module, Page, Paragraph } from 'components'
import { Button } from 'components/inputs/button'
import { PATHS } from 'pages'

export const ErrorBoundaryFallback: React.FunctionComponent = () => {
  function reload() {
    window.location.href = PATHS.root.path
  }

  return (
    <Page disableMenu={true} disableToolbar={true} title="Error">
      <Grid mdColumns={5} xlColumns={9}>
        <Module mdColumns={3} mdStartColumn={2} xlColumns={3} xlStartColumn={4}>
          <Card accent="danger" shadow={true} status="danger" title="Oh no!">
            <Paragraph>We are so sorry, something went terribly wrong!</Paragraph>
            <Button onClick={reload}>Reload application</Button>
          </Card>
        </Module>
      </Grid>
    </Page>
  )
}
