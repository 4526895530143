import { BOOKING_DND_TYPE, IDragInfo, INBOX_DND_TYPE } from 'constants/dragtypes'

import { useCallback, useEffect, useRef, useState } from 'react'
import { DropTargetMonitor, useDrop, XYCoord } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'

import { Notification } from 'components'
import { ScheduledTask } from 'components/draggable-task/scheduledtask'
import { IBooking, useStores } from 'models'
import { snapNumber, useThrottle, useThrottleOnAnimationFrame } from 'utils'

import { AvailabilityDialog } from './availabilitymodal'
import { TimelineHeader } from './timelineheader'
/* Import interfaces here */
import { ITimelineHook, ITimelineStyle, IWorkerScheduleProps } from './workerschedule.interfaces'
import { StyledWorkerSchedule, TaskPreview, Timeline, TimelineCanvas, TimelineContainer } from './workerschedule.styles'

/**
 * Draw a timeline to a canvas
 * @param ctx - Context to draw timeline to
 * @param startTime - Time on first line
 * @param endTime - Time on last line
 * @param width - width of canvas
 * @param height - height of canvas
 * @param style - style of the timeline
 */
const drawTimeline = (
  ctx: CanvasRenderingContext2D,
  startTime: number,
  endTime: number,
  width: number,
  height: number,
  style: ITimelineStyle,
): void => {
  const periodLinesCount = Math.floor((endTime - startTime) / 15)
  const pixelsBetweenLines = width / periodLinesCount
  const legendY = Math.floor(16 * 1.1) // 1.1 rem
  const legendHeight = legendY + 3

  ctx.fillStyle = '#000'
  ctx.textAlign = 'center'
  ctx.lineWidth = 1
  ctx.strokeStyle = '#ededed'
  ctx.font = style.legendFont

  if (pixelsBetweenLines <= 0) return // Might occur if windows is resized

  for (let x = pixelsBetweenLines, period = 1; x <= width; x += pixelsBetweenLines, period++) {
    const lx = Math.floor(x) + 0.5
    const time = startTime + period * 15
    const hour = Math.floor(time / 60)
    const minute = time % 60

    ctx.beginPath()
    ctx.moveTo(lx, legendHeight)
    ctx.lineTo(lx, height)
    ctx.stroke()
    if (time % 60 == 0) {
      ctx.fillText(`${hour}:${String(minute).padStart(2, '0')}`, lx, legendY)
    }
  }
}

/**
 * A hook to draw a timeline on a canvas
 * @param startWorkTime - time, in minutes from midnight, when workday is starting
 * @param endWorkTime - time, in minutes from midnight, when workday is ending
 */
const useTimeline = (startWorkTime: number, endWorkTime: number, numberOfLanes: number): ITimelineHook => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const startTime = startWorkTime - 15 // Show 15min before and after workday
  const endTime = endWorkTime + 15
  const [pixelTimeRatio, setPixelTimeRatio] = useState(1)
  const [width, setWidth] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const onResizeWindow = _.debounce((): void => {
      setWindowWidth(window.innerWidth)
    }, 100)

    window.addEventListener('resize', onResizeWindow)
    return (): void => {
      window.removeEventListener('resize', onResizeWindow)
    }
  }, [])

  useEffect(() => {
    if (canvasRef.current === null) return

    setPixelTimeRatio(canvasRef.current.clientWidth / (endTime - startTime))
    setWidth(canvasRef.current.clientWidth)
  }, [endTime, startTime, windowWidth])

  useEffect(() => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d', { alpha: false })
    if (!ctx) return

    const scale = window.devicePixelRatio
    const width = canvas.clientWidth
    const height = canvas.clientHeight

    canvas.width = width * scale
    canvas.height = height * scale

    ctx.scale(scale, scale)
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, width, height)

    const css = window.getComputedStyle(canvas, null)

    drawTimeline(ctx, startTime, endTime, width, height, {
      legendColor: '#ccc',
      legendFont: css.font,
    })
  }, [endTime, endWorkTime, startTime, startWorkTime, windowWidth, numberOfLanes])

  return {
    canvasRef,
    pixelTimeRatio,
    convertTimeToPosition: (time: number): number => {
      const pos = time * pixelTimeRatio - startTime * pixelTimeRatio
      return pos
    },
    convertPositionToTime: (xpos: number): number => {
      const time = startTime + xpos / pixelTimeRatio
      return time
    },
    canvasWidth: width,
  }
}

/**
 * WorkerSchedule represents one day of work for a worker.
 * Tasks can be dragged to other WorkerSchedules
 */
export const WorkerSchedule = observer(function WorkerSchedule(props: IWorkerScheduleProps): JSX.Element {
  const {
    startTime: startWorkTime,
    endTime: endWorkTime,
    worker,
    date,
    deleted,
    onDropBooking,
    onDropFromInbox,
    onEditBooking,
    onResizeBooking,
  } = props
  const { t } = useTranslation('schedule')
  const { bookings: bookingsStore, ui } = useStores()
  const [showAvailabilityDialog, setShowAvailabilityDialog] = useState<boolean>(false)
  const lanesCountRef = useRef(1)
  const [lanesCount, setLanesCount] = useState(lanesCountRef.current)
  const [previewX, setPreviewX] = useState(0)

  const { canvasRef, convertPositionToTime, convertTimeToPosition, canvasWidth, pixelTimeRatio } = useTimeline(
    startWorkTime,
    endWorkTime,
    lanesCount,
  )

  const throtteledHover = useThrottleOnAnimationFrame((item: IDragInfo, monitor: DropTargetMonitor) => {
    const itemType = monitor.getItemType()

    if (!itemType || !monitor.isOver()) return // no need to calculate anything of not hovering here

    // Calculate offset where booking should start
    const canvasLeft = canvasRef.current?.getBoundingClientRect().left || 0
    let offset = canvasLeft
    const clientOffsetX = monitor.getInitialClientOffset()?.x || 0
    const booking = bookingsStore.get(item.id)
    const width = booking.length * pixelTimeRatio

    if (itemType === BOOKING_DND_TYPE && booking.startTime !== null) {
      const mfm = booking.minutesFromMidnight(date)

      const startTimeOffset =
        mfm.startTime < startWorkTime && mfm.endTime < endWorkTime
          ? convertTimeToPosition(mfm.endTime) - (width || 100)
          : convertTimeToPosition(mfm.startTime)

      offset = clientOffsetX - startTimeOffset
    }

    const clientOffset = monitor.getClientOffset()?.x || 0
    const x = snapNumber(clientOffset - offset, 15 * pixelTimeRatio)
    setPreviewX(x)
  })

  const [collected, dropRef] = useDrop({
    canDrop: () => !worker.isUnavailable,
    accept: [BOOKING_DND_TYPE, INBOX_DND_TYPE],
    drop(item: IDragInfo, monitor) {
      const { x } = monitor.getClientOffset() as XYCoord
      const { x: x0 } = monitor.getInitialClientOffset() as XYCoord

      const canvasLeft = canvasRef.current?.getBoundingClientRect().left || 0
      const initialTime = convertPositionToTime(x0 - canvasLeft)
      const dropTime = convertPositionToTime(x - canvasLeft)

      if (item.sourceWorkerId) {
        onDropBooking(worker, item.sourceWorkerId, item.id, initialTime, dropTime)
      } else {
        onDropFromInbox(worker, item.id, dropTime)
      }
    },
    collect(monitor: DropTargetMonitor) {
      const item: IDragInfo = monitor.getItem()
      if (!item || !monitor.isOver) return { isOver: false, offset: 0 } // Nothing to do

      const booking = bookingsStore.get(item.id)
      const width = booking.length * pixelTimeRatio

      return {
        isOver: monitor.isOver(),
        previewWidth: width,
      }
    },
    hover: throtteledHover,
  })

  const closeAvailabilityModal = useCallback((): void => setShowAvailabilityDialog(false), [])

  const showAvailabilityModal = useCallback((): void => setShowAvailabilityDialog(true), [])

  const handleResizeBooking = useCallback(
    (booking: IBooking, diff: number) => {
      const timeDiff = diff / pixelTimeRatio
      const newLength = booking.length + timeDiff
      onResizeBooking(booking, newLength < 15 ? 15 : newLength)
    },
    [onResizeBooking, pixelTimeRatio],
  )

  /**
   * Toggle availability of a user
   *
   */
  const toggleAvailability = useCallback(async (): Promise<void> => {
    if (worker.isUnavailable) {
      worker.unavailable.forEach(async unavailable => {
        await worker.setAvailable(parseInt(unavailable.id))

        setShowAvailabilityDialog(false)

        toast(
          <Notification
            description={t('notification.available.description', {
              workerName: worker.name,
            })}
            status="info"
            title={t('available')}
          />,
        )
      })
    } else {
      const today = new Date()
      /**
       * Two years from today.
       *
       * NOTE: This is a temporary workaround until we have added support for planning and scheduling for
       * periods.
       */
      const endDate: Date = new Date(today.setFullYear(new Date().getFullYear() + 2))

      await worker.setUnavailable(today, endDate)

      setShowAvailabilityDialog(false)

      toast(
        <Notification
          description={t('notification.unavailable.description', {
            workerName: worker.name,
          })}
          status="warning"
          title={t('unavailable')}
        />,
      )
    }
  }, [t, worker])

  // Converts the shifts into a list with shifts, where each list contains one "lane"
  const taskLanes = _.chain(worker.bookingsAtDate(date, ui.selectedStation?.id))
    .sortBy('startTime')
    .reduce((lanes: IBooking[][], curr) => {
      for (let i = 0; ; i++) {
        if (!lanes[i]) {
          return [...lanes, [curr]] // Create new lane
        }

        const lastBooking = lanes[i][lanes[i].length - 1]
        const currentMinutesFromMidnight = curr.minutesFromMidnight(date)
        const lastBookingMinutesFromMidnight = lastBooking.minutesFromMidnight(date)

        if (
          currentMinutesFromMidnight.startTime < lastBookingMinutesFromMidnight.startTime ||
          currentMinutesFromMidnight.startTime >= lastBookingMinutesFromMidnight.endTime
        ) {
          lanes[i].push(curr)
          return [...lanes]
        }
      }
    }, [])
    .value()

  if (lanesCountRef.current !== taskLanes.length) {
    setLanesCount(taskLanes.length)
    lanesCountRef.current = taskLanes.length
  }

  return (
    <>
      <StyledWorkerSchedule id={`worker-${worker.id}`}>
        <TimelineHeader date={date} worker={worker} workerDeleted={deleted} onClick={showAvailabilityModal} />
        <Timeline unavailable={worker.isUnavailable || deleted}>
          <TimelineContainer
            ref={worker.isUnavailable || deleted ? undefined : dropRef}
            numberOfLanes={taskLanes.length}
          >
            <TimelineCanvas ref={canvasRef} />
            {taskLanes.map((bookings, lane) =>
              bookings.map(booking => (
                <ScheduledTask
                  key={booking.id}
                  booking={booking}
                  lane={lane}
                  left={convertTimeToPosition(booking.minutesFromMidnight(date).startTime)}
                  maxWidth={canvasWidth}
                  originX={canvasRef.current?.getBoundingClientRect().left ?? 0}
                  pixelTimeRatio={pixelTimeRatio}
                  right={convertTimeToPosition(booking.minutesFromMidnight(date).endTime)}
                  worker={worker}
                  onEditBooking={onEditBooking}
                  onResizeEnd={handleResizeBooking}
                />
              )),
            )}
            {collected.isOver && <TaskPreview left={previewX} width={collected.previewWidth || 100} />}
          </TimelineContainer>
        </Timeline>
      </StyledWorkerSchedule>
      {showAvailabilityDialog && (
        <AvailabilityDialog
          available={!worker.isUnavailable}
          workerName={worker.name}
          onAccept={toggleAvailability}
          onClose={closeAvailabilityModal}
        />
      )}
    </>
  )
})
