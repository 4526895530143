import { BookingDto } from '@cdab/cplan-api-client'
import { AxiosResponse } from 'axios'
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns'
import _ from 'lodash'
import fp from 'lodash/fp'
import { applySnapshot, flow, getRoot, getSnapshot, Instance, types } from 'mobx-state-tree'

import { Assignment } from 'models/assignmentsStore'
import { tuple } from 'utils/ts'

import { withEnvironment } from '../extensions'
import { IRootStore } from '../rootStore'

import { Booking, IBooking, IBookingAssignment } from './booking'
import { DEFAULT_LENGTH } from './constants'

interface IMoveBookingOptions {
  toWorkerID: IBookingAssignment | IBookingAssignment[]
  newStartTime: Date
  newEndTime?: Date
  fromWorkerID?: string
  clearWorkers?: boolean
  newStation?: string
}

const BookingStatus = {
  notStarted: 1,
  inProgress: 2,
  done: 3,
} as const

export type TBookingStatus = typeof BookingStatus[keyof typeof BookingStatus]

type TSortTuple = [number, boolean | undefined]
const sortByTuple = (a: TSortTuple, b: TSortTuple) => a[0] - b[0]

export const BookingStore = types
  .model('BookingStore', {
    bookings: types.map(Booking),
  })
  .extend(withEnvironment)
  .views(self => {
    return {
      get allIDs(): string[] {
        return Array.from(self.bookings.keys())
      },
      /**
       * Get Bookings from a list of IDs
       * @param bookingIds - List with IDs
       */
      fromIds(bookingIDs: string[]): IBooking[] {
        return bookingIDs.map(this.get)
      },
      /**
       * get a booking from the store
       * @param id - ID of booking
       */
      get(id: string): IBooking {
        const booking = self.bookings.get(id)
        if (!booking) throw new Error(`Booking with id '${id} does not exist in store`)
        return booking
      },
      /**
       * get all items in the inbox
       */
      inbox(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds: stationIds, inbox: true })
      },
      /**
       * get a filtered subset of items in the store
       * @param filterParams - Filter bookings with this
       */
      filtered(filterParams: {
        start?: Date
        end?: Date
        stationIds?: string[] | string
        inbox?: boolean
        delayed?: boolean
        inProgress?: boolean
        done?: boolean
      }): IBooking[] {
        return Array.from(self.bookings.values()).filter((b: IBooking) => {
          // Filter on stations
          const stationFilter =
            filterParams.stationIds === undefined
              ? undefined
              : Array.isArray(filterParams.stationIds)
              ? filterParams.stationIds
              : [filterParams.stationIds as string]
          if (stationFilter !== undefined && !stationFilter.includes(b.stationId)) {
            return false
          }

          if (filterParams.inbox !== undefined && filterParams.inbox !== b.isInInbox) return false
          if (filterParams.delayed !== undefined && filterParams.delayed !== b.isDelayed) return false
          if (filterParams.inProgress !== undefined && filterParams.inProgress !== b.isOngoing) return false
          if (filterParams.done !== undefined && filterParams.done !== b.isCompleted) return false

          // If no filtering on dates, we are done!
          if (filterParams.start === undefined && filterParams.end === undefined) return true

          if (!b.startTime || !b.endTime) return false
          if (filterParams.start === undefined || filterParams.end === undefined) return false

          return (
            (b.startTime >= filterParams.start && b.startTime <= filterParams.end) || // Starting in period
            (b.endTime >= filterParams.start && b.endTime <= filterParams.end) || // Ending in period
            (b.startTime <= filterParams.start && b.endTime >= filterParams.end)
          ) // wraps period
        })
      },
      delayed(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds, delayed: true })
      },
      ongoing(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds, inProgress: true })
      },
      completed(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds, done: true })
      },
      get completedToday(): IBooking[] {
        return this.filtered({
          start: startOfDay(new Date(Date.now())),
          end: endOfDay(new Date(Date.now())),
          done: true,
        })
      },
    }
  })
  .actions(self => {
    return {
      addBooking(id: string, createdAt: Date, startTime: Date | null, length: number): IBooking {
        const booking = Booking.create({
          id,
          createdAt,
          startTime,
          length,
          stationId: '',
        })
        self.bookings.set(id, booking)
        self.allIDs.push(booking.id)

        return booking
      },
      removeBookingFromStore(id: string): void {
        const { assignments } = getRoot<IRootStore>(self)
        assignments.RemoveBookingAssignments(id)

        self.bookings.delete(id)
      },
      /**
       * Add or update a booking in the store
       * @param dto - booking from server
       */
      async addBookingFromDto(dto: BookingDto): Promise<void> {
        const { workers, organization, assignments } = getRoot<IRootStore>(self)
        if (dto.id === undefined) throw new Error(`Booking from server with no id`)
        if (!dto.isInInbox && dto.start === undefined)
          throw new Error(`Booking from server that should both be scheduled and unscheduled`)
        const { attributes } = getRoot<IRootStore>(self)

        const dtoId = String(dto.id)
        const existingBooking = self.bookings.get(dtoId)

        if (existingBooking && existingBooking.isLocked) {
          // Don't update a locked booking
          return
        }

        const start = parseISO(dto.start || '')
        const end = parseISO(dto.end || '')
        const length = dto.isInInbox ? dto.lengthInMinutes : organization.calculatePeriod(start, end)

        const snapshot = {
          id: String(dto.id),
          createdAt: dto.createdAt ? parseISO(dto.createdAt) : new Date(), // TODO: Det här behöver justeras så att vi inte behöver kolla om det är undefined. Se över det här i API:et tillsammans med övriga endpoints
          startTime: dto.isInInbox ? null : start,
          workStarted: (dto.workStarted && parseISO(dto.workStarted)) || null,
          workFinished: (dto.workFinished && parseISO(dto.workFinished)) || null,
          length: length && length > 0 ? length : DEFAULT_LENGTH,
          stationId: String(dto.stationId),
          externalId: dto.externalId || undefined, //remove null value
          status: dto.bookingStatusId ? organization.bookingStatusWithId(dto.bookingStatusId)?.id : null,
        }

        // Update or create a booking
        let booking: IBooking
        if (existingBooking) {
          const oldSnapshot = getSnapshot(existingBooking)
          booking = existingBooking
          applySnapshot(booking, Object.assign({}, oldSnapshot, snapshot))
        } else {
          booking = Booking.create(snapshot)
          self.bookings.set(dtoId, booking)
          self.allIDs.push(booking.id)
        }

        dto.attributes?.forEach(ba => {
          if (ba.typeId === undefined) {
            throw new Error(`Attribute without Id from server`)
          }
          attributes.add(String(ba.typeId), {
            id: String(ba.typeId),
            name: ba.type || '',
            type: ba.dataType || 'string',
            shortcode: ba.shortCode,
          })
          booking.updateAttribute(String(ba.typeId), ba.value || '')
        })

        const newWorkerArray =
          dto.workerIds?.map(ba => tuple([ba.workerId || 0, ba.isShadowWorker])).sort(sortByTuple) ?? []

        const oldWorkerArray = assignments
          .ByBookingId(booking.id)
          .map(bw => tuple([Number.parseInt(bw.workerId) || 0, bw.isShadowBooking]))
          .sort(sortByTuple)

        if (_.isEqual(oldWorkerArray, newWorkerArray)) return // We don't have to update workers

        assignments.RemoveBookingAssignments(booking.id)

        const workerIdsToGetFromServer: string[] =
          dto.workerIds
            ?.filter(({ workerId }) => !!workerId && !workers.byId.has(workerId.toString()))
            .map(({ workerId }) => workerId!.toString()) || []

        if (workerIdsToGetFromServer.length > 0) {
          await workers.loadFromServer(workerIdsToGetFromServer)
        }

        // assign workers to booking
        dto.workerIds?.forEach(async w => {
          assignments.Assign(
            Assignment.create({
              bookingId: booking.id,
              workerId: String(w.workerId),
              isShadowBooking: !!w.isShadowWorker,
            }),
          )
        })
      },
      _moveBooking(bookingID: string, options: IMoveBookingOptions): IBooking {
        const { assignments } = getRoot<IRootStore>(self)
        const booking = self.get(bookingID)
        const { newStartTime, newEndTime, fromWorkerID, newStation, clearWorkers = false } = options
        const toWorkerIDs = !Array.isArray(options.toWorkerID) ? [options.toWorkerID] : options.toWorkerID

        booking.moveStartTime(newStartTime)

        if (newEndTime) {
          booking.setEndTime(newEndTime)
        }
        if (fromWorkerID) {
          assignments.RemoveAssignment(bookingID, fromWorkerID)
        }

        if (newStation) {
          booking.stationId = newStation
        }

        if (clearWorkers) {
          assignments.RemoveBookingAssignments(booking.id)
        }

        toWorkerIDs.forEach(({ assignedTo, isShadowAssignment }) => {
          assignments.Assign(
            Assignment.create({
              bookingId: booking.id,
              workerId: assignedTo,
              isShadowBooking: isShadowAssignment,
            }),
          )
        })

        return booking
      },
      _moveBookingToInbox(bookingID: string): IBooking {
        const booking = self.get(bookingID)
        booking.moveToInbox()
        return booking
      },
    }
  })
  // Async actions
  .actions(self => {
    /** Update bookings from server */
    const updateBookings = flow(function* (bookingIds: string[]) {
      if (bookingIds.length === 0) {
        // Nothing to do
        return
      }

      for (const id of bookingIds) {
        const booking = self.get(id)
        booking.setIsLoading(true)

        try {
          const ret: AxiosResponse<BookingDto> = yield self.environment.api.bookings.getBooking(Number(id))
          const dto = ret.data
          self.addBookingFromDto(dto)
        } catch (e) {
          self.removeBookingFromStore(id)
        }
      }
    })

    /**
     * Update inbox from server
     */
    const updateInboxFromServer = flow(function* () {
      const res = yield self.environment.api.bookings.getInboxBookings()
      const bookings: BookingDto[] = res.data

      const inboxIds: string[] = fp.compose(
        fp.map(String),
        fp.filter(id => id !== undefined),
        fp.map(fp.get('id')),
      )(bookings)

      const alreadyInInbox: string[] = self.inbox().map(b => b.id)
      const bookingsToBeUpdated: string[] = _.difference(alreadyInInbox, inboxIds)

      bookings.forEach(booking => {
        self.addBookingFromDto(booking)
      })

      yield updateBookings(bookingsToBeUpdated)
    })

    /**
     * Load bookings from the server, that is scheduled at this date
     * @param start
     * @param end
     * @param options
     */
    const updateBookingsFromServer = flow(function* (
      start: Date,
      end?: Date,
      options?: { getInbox?: boolean; getOngoing?: boolean; getDelayed?: boolean },
    ) {
      const { getInbox = false, getOngoing = false, getDelayed = false } = options || {}
      let res: AxiosResponse<BookingDto[]>
      try {
        res = yield self.environment.api.bookings.getBookings(
          formatISO(startOfDay(start)),
          formatISO(endOfDay(end || start)),
          getInbox,
          getOngoing,
          getDelayed,
        )
      } catch (error) {
        if (error.response && error.response.status === 401) return // 401 already handled
        throw error
      }

      // Add or update bookings bookings in store
      res.data.forEach(self.addBookingFromDto)

      // Remove bookings from store
      /** IDs of bookings to remove */
      const bookingsToRemove: string[] = []
      self.allIDs.forEach(id => {
        // Compare existing IDs in store to response and remove bookings that does not exist in response
        if (
          !res.data.find(resObj => {
            return resObj.id?.toString() === id
          })
        ) {
          bookingsToRemove.push(id)
        }
      })
      bookingsToRemove.forEach(id => self.removeBookingFromStore(id))
    })

    const { assignments } = getRoot<IRootStore>(self)

    const moveBookingHelper = (booking: IBooking) =>
      self.environment.api.bookings.moveBooking(Number(booking.id), {
        stationId: Number(booking.stationId),
        start: booking.startTime ? formatISO(booking.startTime) : undefined,
        end: booking.endTime ? formatISO(booking.endTime) : undefined,
        workers: assignments.ByBookingId(booking.id).map(({ workerId }) => Number.parseInt(workerId)),
      })

    /**
     * Move a booking
     */
    const moveBooking = flow(function* (bookingID: string, options: IMoveBookingOptions) {
      const booking = self.get(bookingID)
      const snapshot = getSnapshot(booking)

      const updatedBooking = self._moveBooking(bookingID, options)

      try {
        booking.setIsLoading(true)

        const res: AxiosResponse<BookingDto> = yield moveBookingHelper(updatedBooking)
        self.addBookingFromDto(res.data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        // Restore booking
        applySnapshot(booking, snapshot)
      } finally {
        self.bookings.get(bookingID)?.setIsLoading(false)
      }
    })

    const setStatus = flow(function* (bookingId: string, statusId: number | null) {
      yield self.environment.api.bookings.setStatus(parseInt(bookingId), { statusId: statusId })
    })

    /**
     * resize a booking
     * @param bookingId - Id of booking
     * @param length - new length of booking, in minutes
     */
    const resizeBooking = flow(function* (bookingID: string, length: number) {
      const booking = self.get(bookingID)
      const snapshot = getSnapshot(booking)

      try {
        booking.setIsLoading(true)
        booking.setLength(length)
        const res: AxiosResponse<BookingDto> = yield moveBookingHelper(booking)
        self.addBookingFromDto(res.data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        applySnapshot(booking, snapshot)
      } finally {
        self.bookings.get(bookingID)?.setIsLoading(false)
      }
    })

    /**
     * move a booking back to the inbox
     */
    const moveBookingToInbox = flow(function* (bookingId: string) {
      const booking = self.get(bookingId)
      const snapshot = getSnapshot(booking)
      const updatedBooking = self._moveBookingToInbox(bookingId)

      try {
        const res: AxiosResponse<BookingDto> = yield self.environment.api.bookings.moveBooking(Number(bookingId), {
          stationId: Number(updatedBooking.stationId),
          workers: [],
        })
        self.addBookingFromDto(res.data)
      } catch (e) {
        // Restore booking
        self.bookings.set(bookingId, snapshot)
      }
    })

    /**
     * Start the work, setting status to ongoing
     */
    const startWork = flow(function* (bookingId: string) {
      const booking = self.get(bookingId)
      const snapshot = getSnapshot(booking)
      const date = { value: formatISO(new Date()) }

      try {
        booking.setIsLoading(true)
        yield self.environment.api.bookings.startWork(bookingId, parseInt(bookingId), date)
        yield updateBookings([bookingId])
        booking.setIsLoading(false)
      } catch (e) {
        // Restore booking
        self.bookings.set(bookingId, snapshot)
      }
    })

    /**
     * sinish the work, setting status to completed
     */
    const finishWork = flow(function* (bookingId: string) {
      const booking = self.get(bookingId)
      const snapshot = getSnapshot(booking)
      const date = { value: formatISO(new Date()) }

      try {
        booking.setIsLoading(true)
        yield self.environment.api.bookings.finishWork(bookingId, parseInt(bookingId), date)
        yield updateBookings([bookingId])
        booking.setIsLoading(false)
      } catch (e) {
        // Restore booking
        self.bookings.set(bookingId, snapshot)
      }
    })

    const reverseBookingStatus = flow(function* (bookingId: string, status: TBookingStatus) {
      const booking = self.get(bookingId)
      const snapshot = getSnapshot(booking)
      try {
        booking.setIsLoading(true)
        yield self.environment.api.bookings.reverseBookingStatus(parseInt(bookingId), status)
        yield updateBookings([bookingId])
        booking.setIsLoading(false)
      } catch (e) {
        self.bookings.set(bookingId, snapshot)
        throw e
      }
    })

    return {
      updateBookingsFromServer,
      updateInboxFromServer,
      moveBooking,
      setStatus,
      moveBookingToInbox,
      resizeBooking,
      startWork,
      finishWork,
      reverseBookingStatus,
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingStore extends Instance<typeof BookingStore> {}
