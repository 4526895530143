import { Skeleton } from 'components/skeleton'

import { SkeletonBooking, SkeletonInbox } from './dashboard.styles'

export const Loading = (): JSX.Element => (
  <SkeletonInbox>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
  </SkeletonInbox>
)
