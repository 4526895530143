import { useTranslation } from 'react-i18next'

import { Button, DialogBox, Paragraph } from 'components'

interface IAvailabilityDialogProps {
  available: boolean
  onClose: () => void
  onAccept: () => void
  workerName: string
}

export const AvailabilityDialog = (props: IAvailabilityDialogProps): JSX.Element => {
  const { available, workerName, onClose, onAccept } = props
  const { t } = useTranslation('schedule')
  const headerTitle = available ? t('availabilityModal.unavailable.title') : t('availabilityModal.available.title')
  const description = available
    ? t('availabilityModal.unavailable.description', { workerName })
    : t('availabilityModal.available.description', { workerName })

  return (
    <DialogBox showCloseButton header={headerTitle} inPortal={true} onClose={onClose}>
      <Paragraph textAlign="center">{description}</Paragraph>
      <DialogBox.Footer>
        <Button appearance="ghost" onClick={onClose}>
          {t('common:close')}
        </Button>
        <Button status="primary" onClick={onAccept}>
          {t('common:yes')}
        </Button>
      </DialogBox.Footer>
    </DialogBox>
  )
}
