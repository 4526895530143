import { useCallback, useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useStores } from 'models'
import { PATHS } from 'pages'
import { useRouter } from 'utils'

const PrivateRoute = observer(function PrivateRoute(props: RouteProps) {
  const { component: Component, ...rest } = props
  const { location } = useRouter()
  const { userStore } = useStores()

  const redirectTo = useMemo(
    () => ({
      pathname: PATHS.login.path,
      search: location.search,
      state: { referrer: { pathname: location.pathname } },
    }),
    [location.pathname, location.search],
  )

  const render = useCallback(
    props => {
      if (!Component) throw new Error(`PrivateRoute must have component`)

      return userStore.isAuthenticated ? <Component {...props} /> : <Redirect to={redirectTo} />
    },
    [Component, redirectTo, userStore.isAuthenticated],
  )

  return <Route render={render} {...rest} />
})

export default PrivateRoute
