import { Api } from '@cdab/cplan-api-client'
import { flow, getEnv, Instance, types } from 'mobx-state-tree'

import { AssignmentsStore, IAssignmentsStore } from 'models/assignmentsStore'
import { AttributeStore, BookingStore, IAttributeStore, IBookingStore } from 'models/bookingStore'
import { CommentStore, ICommentStore } from 'models/comments'
import { IEnvironment } from 'models/environment'
import { withEnvironment } from 'models/extensions/withEnvironment'
import { OrganizationStore } from 'models/organizationStore'
import { IStations, StationsStore } from 'models/stationsStore'
import { IUIStore, UIStore } from 'models/uiStore'
import { IUserStore, UserStore } from 'models/userStore'
import { IWorkerStationStore, WorkerStationStore } from 'models/workerStationStore'
import { IWorkersStore, WorkersStore } from 'models/workerStore/workerStore'

// Interface defined manually to overcome circular imports in typescript
export interface IRootStore {
  userStore: IUserStore
  workers: IWorkersStore
  assignments: IAssignmentsStore
  attributes: IAttributeStore
  stationWorkers: IWorkerStationStore
  ui: IUIStore
  stations: IStations
  bookings: IBookingStore
  comments: ICommentStore

  organization: Instance<typeof OrganizationStore>

  setup: () => Promise<void>

  reset: () => Promise<void>

  api: Api
}

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model({
    userStore: UserStore,
    workers: WorkersStore,
    assignments: AssignmentsStore,
    bookings: BookingStore,
    stations: StationsStore,
    attributes: AttributeStore,
    organization: OrganizationStore,
    comments: CommentStore,
    stationWorkers: WorkerStationStore,
    ui: UIStore,
  })
  .extend(withEnvironment)
  .views(self => ({
    get api() {
      return self.environment.api
    },
  }))
  .actions(self => {
    const setup = flow(function* () {
      self.ui.setIsLoading(true)
      const env: IEnvironment = getEnv(self)

      yield env.setup()

      // reactotron logging
      if (process.env.NODE_ENV === 'development') {
        env.reactotron.setRootStore(self)
      }

      yield self.userStore.setup()

      self.ui.setIsLoading(false)
    })

    const reset = flow(function* () {
      self.userStore = UserStore.create({})
      self.workers = WorkersStore.create({})
      self.bookings = BookingStore.create({})
      self.stations = StationsStore.create({})
      self.attributes = AttributeStore.create({})
      self.organization = OrganizationStore.create({})
      self.comments = CommentStore.create({})
      self.stationWorkers = WorkerStationStore.create({})
      self.ui = UIStore.create({ signalRConnectionStatus: 'disconnected' })

      yield setup()
    })

    return {
      setup,
      reset,
    }
  })

/**
 * The RootStore instance.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface IRootStore extends Instance<typeof RootStoreModel> {}
