import styled from 'styled-components/macro'

import { Card } from 'components/card'
import { FormSection } from 'components/form-section'
import { CircleButton } from 'components/inputs/circle-button'
import { List } from 'components/list'

export const StyledComments = styled.div`
  position: relative;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex: 1;
`
export const StyledComment = styled.div`
  color: ${({ theme }): string => theme.colors.TEXT_BASIC_COLOR};
  font-size: 1rem;
  font-weight: 600;
`

export const StyledCircleButton = styled(CircleButton)`
  right: 0;
  top: 0;
  margin: 0;
  margin-left: 1rem;
  background-color: ${({ theme }) => theme.colors.TEXT_BASIC_COLOR};
`
export const PopperContainer = styled.div`
  z-index: 100;
  padding: 0.5rem;
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 0.5rem;
`

export const CommentCard = styled(Card)`
  width: 25rem;
  max-width: 75vw;
  box-shadow: ${({ theme }) => theme.support.SHADOW};
`

export const StyledFormSection = styled(FormSection)`
  padding-left: 0;
  padding-right: 0;
`

export const StyledDeleteButton = styled(CircleButton)`
  margin: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.TEXT_DANGER_COLOR};

  &:hover {
    background: ${({ theme }) => theme.baseColors.COLOR_BASIC_300};
  }
`

const border = theme => `${theme.support.DIVIDER_WIDTH} ${theme.support.DIVIDER_STYLE} ${theme.support.DIVIDER_COLOR}`
export const StyledCommentList = styled(List)`
  border-top: ${({ theme }) => border(theme)};
`
