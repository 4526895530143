import { Api } from '@cdab/cplan-api-client'

import { apiConfig } from 'config'

let ReactotronDev

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { Reactotron } = require('services/reactotron')
  ReactotronDev = Reactotron
}

export interface IEnvironment {
  reactotron: typeof ReactotronDev
  setup: () => Promise<void>
  api: Api
}

/**
 * The environment is a place where services and shared dependencies between
 * models live. They are made available to every model via dependency injection.
 */
export class Environment implements IEnvironment {
  /**
   * Reactotron is only available in dev.
   */
  reactotron: typeof ReactotronDev

  /**
   * Our api.
   */
  api: Api

  localStorage: Storage

  constructor() {
    // create each service
    if (process.env.NODE_ENV === 'development') {
      // dev-only services
      this.reactotron = new ReactotronDev()
    }

    this.localStorage = window.localStorage // FIXME: Check if localStorage is available, and if not assign dummy storage

    this.api = new Api(apiConfig)
  }

  async setup(): Promise<void> {
    // allow each service to setup
    if (process.env.NODE_ENV === 'development') {
      await this.reactotron.setup()
    }
  }
}
