import { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { faCalendar, faNotesMedical, faSpinner, faUser } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import { isValid, parseISO } from 'date-fns'
import { TFunction } from 'i18next'
import { reaction } from 'mobx'
import { Observer, observer } from 'mobx-react-lite'

import { Grid } from 'components'
import { useConfirmation } from 'components/confirm-modal'
import { DatePicker } from 'components/date-picker'
import { DialogBox } from 'components/dialogbox'
import { FormSection } from 'components/form-section'
import { Button } from 'components/inputs/button'
import { MultiSelect } from 'components/inputs/multiselect'
import { Caption } from 'components/typography/caption'
import { IBooking, TBookingStatus, useStores } from 'models'
import { formatDateTime, formatPeriod } from 'utils/date'

import { IBookingModalProps } from './booking-modal.interfaces'
import {
  AttributeGrid,
  AttributeName,
  AttributeValue,
  Step,
  StepConatiner,
  StepDivider,
  StyledBookingModal,
} from './booking-modal.styles'
import { validationSchema } from './booking-modal.validation'

const nextStepText = (t: TFunction, step: number): string => {
  return [t('schedule:startWork'), t('schedule:finishWork')][step]
}

// eslint-disable-next-line react/display-name
const BookingStepper = memo(
  ({ booking }: { booking: IBooking }) => {
    const { t } = useTranslation('schedule')
    const { bookings } = useStores()
    const [steps, setSteps] = useState<string[]>([])
    const [loadingStep, setLoadingStep] = useState<number | null>(null)

    const [confirmStatusChange, ConfirmStatusChangeDialog] = useConfirmation(
      t(booking.isOngoing ? 'confirmMarkAsDone' : 'confirmMarkAsOngoing'),
      [
        { text: t('common:no'), value: false },
        { text: t('common:yes'), value: true },
      ],
    )

    const [confirmReverseStatus, ConfirmReverseDialog] = useConfirmation(t('confirmReverseStatus'), [
      { text: t('common:no'), value: false },
      { text: t('common:yes'), value: true },
    ])

    useEffect(() => {
      const dispose = reaction(
        () => [booking.isDelayed],
        ([isDelayed]) => {
          setSteps([
            isDelayed ? t('dashboard:delayed') : t('dashboard:notStarted'),
            t('dashboard:ongoing'),
            t('dashboard:completed'),
          ])
        },
        { fireImmediately: true },
      )

      return (): void => {
        dispose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const nextStepClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault() // Prevent form submit
      const changeStatus = await confirmStatusChange()
      if (changeStatus) {
        if (!booking.isCompleted && booking.isOngoing) {
          bookings.finishWork(booking.id)
        } else {
          bookings.startWork(booking.id)
        }
      }
    }

    const handleClickStep = useCallback(
      async (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        const statusTexts = [t('dashboard:notStarted'), t('dashboard:ongoing'), t('dashboard:completed')]

        const step = parseInt(e.currentTarget.dataset['step'] || '')
        const status = (step + 1) as TBookingStatus

        if (!(await confirmReverseStatus({ step: statusTexts[step] }))) return

        setLoadingStep(step)
        await bookings.reverseBookingStatus(booking.id, status)
        setLoadingStep(null)
      },
      [booking.id, bookings, confirmReverseStatus, t],
    )

    return (
      <Observer>
        {() => {
          const currentStep = booking.isCompleted ? 2 : booking.isOngoing ? 1 : 0
          return (
            <Grid columns={1}>
              <StepConatiner>
                {steps.map((step, i) => (
                  <Fragment key={step}>
                    <Step
                      active={i === currentStep}
                      clickable={!booking.isLoading && i < currentStep}
                      data-step={i}
                      delayed={booking.isDelayed}
                      finished={booking.isCompleted}
                      ongoing={booking.isOngoing}
                      onClick={handleClickStep}
                    >
                      {loadingStep === i ? <FontAwesomeIcon icon={faSpinner} size="lg" spin={true} /> : step}
                    </Step>
                    <StepDivider />
                  </Fragment>
                ))}
              </StepConatiner>

              {!booking.isCompleted && (
                <Button
                  appearance="outline"
                  disabled={booking.isLoading}
                  fullWidth={true}
                  size="medium"
                  status={booking.isOngoing ? 'success' : 'primary'}
                  onClick={nextStepClick}
                >
                  {nextStepText(t, currentStep)}
                  {booking.isLoading && loadingStep === null && (
                    <>
                      &nbsp; &nbsp; <FontAwesomeIcon icon={faSpinner} size="lg" spin={true} />
                    </>
                  )}
                </Button>
              )}
              {ConfirmStatusChangeDialog}
              {ConfirmReverseDialog}
            </Grid>
          )
        }}
      </Observer>
    )
  },
  (prevProps, props) => {
    return prevProps.booking.id == props.booking.id
  },
)

// TODO: Fix correct type on start and end. Right now it can be a Date or a string
// But this should be refactored
interface IFormData {
  start: any
  end: any
  assignees: string[] | undefined
  status: number | undefined | null
}

const today = new Date()
export const BookingModal = observer(
  ({ bookingId, onClose }: IBookingModalProps): JSX.Element => {
    const { t } = useTranslation('schedule')
    const { bookings, assignments, attributes, workers, organization, stationWorkers } = useStores()

    const booking = bookings.get(bookingId)

    // these values should not update during lifetime of the session, so
    // it should be ok to not wrap it in a reacion
    const workShiftStart = organization.workdayStartime
    const workShiftEnd = organization.workdayEndtime

    const [stationId] = useState(booking.stationId)
    const [length, setLength] = useState(organization.calculatePeriod(booking.startTime, booking.endTime))

    const { register, handleSubmit, formState, errors, getValues, setValue } = useForm<IFormData>({
      mode: 'onChange',
      resolver: yupResolver(validationSchema({ workShiftStart, workShiftEnd })),
      defaultValues: {
        start: formatDateTime(booking.startTime || today),
        end: formatDateTime(booking.endTime || today),
        assignees: assignments.ByBookingId(booking.id).map(({ workerId }) => workerId),
        status: booking.status?.id,
        // TODO: Enable line below when adding support for changing station
        // station: booking.stationId,
      },
    })

    const [confirmClose, ConfirmCloseDialog] = useConfirmation(t('confirmClose'), [
      { text: t('common:no'), value: false },
      { text: t('common:yes'), value: true },
    ])

    const onHandleSubmit = async (data: IFormData): Promise<void> => {
      if (!data.assignees) return

      await bookings.setStatus(booking.id, data.status ?? null)
      await bookings.moveBooking(booking.id, {
        toWorkerID: data.assignees.map(workerID => ({ assignedTo: workerID, isShadowAssignment: false })), // When we move a booking, the shadow-assignment should be unset
        newStartTime: data.start as Date,
        newEndTime: data.end as Date,
        clearWorkers: true,
        // TODO: Enable line below when adding support for changing station
        // newStation: data.station,
      })

      onClose()
    }

    const confirmAndClose = useCallback(async (): Promise<void> => {
      const close = formState.isDirty ? await confirmClose() : true
      if (close) onClose()
    }, [confirmClose, formState.isDirty, onClose])

    const onHandleCancel = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        e.preventDefault() // Prevent form submit
        return confirmAndClose()
      },
      [confirmAndClose],
    )

    /**
   * TODO: Activate when adding support for multiple stations
   const handleChangeStation = (e: React.ChangeEvent<HTMLSelectElement>): void => {
     setStation(e.target.value)
    }
  */

    const handleChangeEndTime = useCallback(() => {
      const start = parseISO(((getValues('start') as unknown) as string) || '')
      const end = parseISO(((getValues('end') as unknown) as string) || '')

      if (isValid(start) && isValid(end)) {
        try {
          setLength(organization.calculatePeriod(start, end))
        } catch {
          setLength(0)
        }
      }
    }, [organization, getValues])

    const handleChangeStartTime = useCallback(() => {
      const start = parseISO(((getValues('start') as unknown) as string) || '')
      if (!isValid(start)) return

      const end = organization.calculateEndTime(start, length)
      setValue('end', formatDateTime(end), { shouldValidate: false })
    }, [getValues, length, organization, setValue])

    const handleFormKeypress = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
      // Prevent action on enter key
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    }, [])

    const { bookingTitle } = booking
    const dirty = formState.isDirty ? '*' : ''
    /**
     * An array of TOption containing available workers for a booking
     *
     * _Rules_
     * - If a worker is available he will show up in the list.
     * - If a worker is unavailable he will not be in the list.
     * - If a worker is deleted he will not be in the list
     *
     * _Exception_
     * - If a worker already is assigned to the booking, he will appear in the list
     *
     */
    const availableWorkers = stationWorkers.byStationId(stationId, true).reduce((acc, cur) => {
      const worker = workers.byId.get(cur.workerId)
      // If no worker in store, filter it away
      if (!worker) return acc
      // If worker is unavailable or deleted, dont add him, unless he's assigned to the booking
      if ((worker.isUnavailable || cur.deleted) && !assignments.WorkerIsAssignedTo(worker.id, bookingId)) return acc

      return [
        ...acc,
        {
          value: worker.id,
          label: worker.name,
        },
      ]
    }, [] as { value: string; label: string }[])

    return (
      <>
        <StyledBookingModal
          showCloseButton
          header={t('editBooking', { bookingTitle, dirty })}
          onClose={confirmAndClose}
        >
          <FormSection border={true}>
            <>
              <BookingStepper booking={booking} />
              {booking.isCompleted && <Caption status="info">{t('completedBookingCantBeEdited')}</Caption>}
            </>
          </FormSection>
          <form onKeyPress={handleFormKeypress} onSubmit={handleSubmit(onHandleSubmit)}>
            <FormSection border={true} icon={<FontAwesomeIcon icon={faCalendar} size="2x" />} title={t('common:date')}>
              <DatePicker
                disabled={booking.isCompleted}
                inputRef={register}
                label={t('startTime')}
                maxTime={formatPeriod(organization.workdayEndtime)}
                minTime={formatPeriod(organization.workdayStartime)}
                name="start"
                openInDialog={true}
                status={errors.start ? 'danger' : 'basic'}
                onChange={handleChangeStartTime}
              />
              <ErrorMessage errors={errors} name="start" />
              {/* TODO: Lägg in stöd för captions på `DateTimeInput`, precis som på `TextInput`. */}
              <DatePicker
                disabled={booking.isCompleted}
                inputRef={register}
                label={t('endTime')}
                maxTime={formatPeriod(organization.workdayEndtime)}
                minTime={formatPeriod(organization.workdayStartime)}
                name="end"
                openInDialog={true}
                status={errors.end ? 'danger' : 'basic'}
                onChange={handleChangeEndTime}
              />
              <ErrorMessage errors={errors} name="end" />
            </FormSection>

            <FormSection border={true} icon={<FontAwesomeIcon icon={faUser} size="2x" />} title={t('assignedTo')}>
              <MultiSelect
                addButtonText="Add"
                disabled={booking.isCompleted}
                inputRef={register}
                name="assignees"
                options={availableWorkers}
              />
              <ErrorMessage errors={errors} name="assignees" />
            </FormSection>

            {/*
              // TODO: Enable this when adding support for multiple stations
              <FormSection border={true}>
              <Heading level="h2">Station</Heading>
              <select ref={register} name="station" onChange={handleChangeStation}>
                {stations.allStations.map(station => (
                  <option key={station.id} value={station.id}>
                    {station.name}
                  </option>
                ))}
              </select>
            </FormSection> */}
            <FormSection border={true} icon={<FontAwesomeIcon icon={faNotesMedical} size="2x" />} title={t('details')}>
              <AttributeGrid columns={2}>
                <AttributeName>{t('common:orderID')}:</AttributeName>
                {booking.externalId && <AttributeValue>{booking.externalId}</AttributeValue>}
                {attributes.all.map(attr => (
                  <Fragment key={attr.id}>
                    <AttributeName>{attr.name}: </AttributeName>
                    <AttributeValue>
                      {attr.isBoolean
                        ? booking.attributes.get(attr.id)?.toLowerCase() === 'true'
                          ? t('common:yes')
                          : t('common:no')
                        : booking.attributes.get(attr.id)}
                    </AttributeValue>
                  </Fragment>
                ))}
                <AttributeName>{t('length')}:</AttributeName>
                <AttributeValue>{length ? formatPeriod(length) : '-'}</AttributeValue>

                <AttributeName>{t('status')}:</AttributeName>
                <AttributeValue>
                  <select
                    ref={register({ setValueAs: value => (value === '' ? null : parseInt(value)) })}
                    name="status"
                  >
                    <option value="">-</option>
                    {organization.bookingStatuses.map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name} ({s.shortcode})
                      </option>
                    ))}
                  </select>
                </AttributeValue>
              </AttributeGrid>
            </FormSection>
            <DialogBox.Footer>
              <Button appearance="ghost" onClick={onHandleCancel}>
                {t('common:cancel')}
              </Button>
              <Button
                disabled={!formState.isValid || Object.entries(errors).length > 0 || formState.isSubmitting}
                status="primary"
                type="submit"
              >
                {t('common:save')}
              </Button>
            </DialogBox.Footer>
          </form>
        </StyledBookingModal>
        {ConfirmCloseDialog}
      </>
    )
  },
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(BookingModal as any).displayName = 'BookingModal'
