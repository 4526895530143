import { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addMinutes } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { useStores } from 'models'
import { ICommentFilterParams } from 'models/comments'
import { useClickaway } from 'utils'

import { CommentForm } from './comment-form'
import { CommentList } from './comment-list'
import { CommentCard, PopperContainer, StyledCircleButton, StyledComment, StyledComments } from './comments.style'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICommentProps extends ICommentFilterParams {
  className?: string
}
export const Comments = observer(function Comments(props: ICommentProps) {
  const { ui, comments: commentStore } = useStores()
  const comments = commentStore.filterComments({
    connections: props.connections,
    start: props.start && addMinutes(props.start, new Date().getTimezoneOffset()),
    end: props.end && addMinutes(props.end, new Date().getTimezoneOffset()),
    tags: props.tags,
    stationId: parseInt(ui.selectedStation?.id ?? '0'),
  })
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [showPopper, setShowPopper] = useState(false)

  const handleClickEdit = useCallback(() => {
    setShowPopper(!showPopper)
  }, [showPopper])

  const close = useCallback((e?: MouseEvent) => {
    if (e) {
      e.preventDefault()
    }
    setShowPopper(false)
  }, [])

  useClickaway(close, showPopper, popperElement)

  const commentText = comments.map(c => c.content).join(', ')

  const { attributes, styles } = usePopper(buttonElement, popperElement)
  return (
    <StyledComments className={props.className}>
      <div>
        <StyledComment>{commentText}</StyledComment>
      </div>
      <StyledCircleButton
        ref={setButtonElement}
        icon={<FontAwesomeIcon icon="comment-alt-lines" />}
        size="small"
        onClick={handleClickEdit}
      />

      {showPopper && (
        <PopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <CommentCard>
            <CommentForm
              connection={props.connections}
              end={props.end}
              start={props.start}
              tags={props.tags}
              onClose={close}
            />
            <CommentList connections={props.connections} end={props.end} start={props.start} tags={props.tags} />
          </CommentCard>
        </PopperContainer>
      )}
    </StyledComments>
  )
})
