import { TThemeFromFn, tuple } from 'components/theming'

export const DialogBoxTheme = () =>
  tuple([
    'DialogBox',
    {
      color: 'black',
      borderRadius: '0.5rem',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 2,
    },
  ])

export type TDialogBoxTheme = TThemeFromFn<typeof DialogBoxTheme>
