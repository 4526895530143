import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { faSurprise } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { EmptyState, List, Skeleton } from 'components'
import { BookingIcon } from 'components/booking-icon'
import { IBooking, useStores } from 'models'
import { PATHS } from 'pages'
import { formatShortISO } from 'utils'

import {
  DashboardItem,
  DashboardItemCardHeader,
  DashboardItemTitle,
  IconWrapper,
  InboxList,
  NumberOfBookingsInInbox,
  ScheduleDayLink,
  Status,
  StyledDate,
  TitleText,
} from './dashboard.styles'
import { Loading } from './loading'

const formatDateTimeDisplay = (date: Date | null) => (date ? format(date, 'yyyy-MM-dd HH:mm') : '')
/**
 * The number of bookings to show in the inbox on Dashboard
 *
 * NOTE: This could be a setting in future versions
 */
interface IBookingItemProps {
  booking: IBooking
}
// eslint-disable-next-line react/display-name
const BookingItem = observer((props: IBookingItemProps) => {
  const { booking } = props
  const { t } = useTranslation('dashboard')
  const path = generatePath(PATHS.schedule.path, { date: formatShortISO(booking.startTime || new Date(Date.now())) })

  return (
    <ScheduleDayLink to={path}>
      <List.Item>
        <List.Item.Left>
          <IconWrapper workStarted={!!booking.workStarted}>
            <BookingIcon size="2x" />
          </IconWrapper>
        </List.Item.Left>
        <List.Item.Title>
          <TitleText workStarted={!!booking.workStarted}>
            <StyledDate>{formatDateTimeDisplay(booking.startTime)}</StyledDate>
            {booking.activeBookingModuleTitleExecutor}
          </TitleText>
        </List.Item.Title>
        <List.Item.Description>
          {booking.plateNumber}
          <Status
            isDelayed={booking.isDelayed}
            workFinished={!!booking.workFinished}
            workStarted={!!booking.workStarted}
          >
            {booking.workStarted ? t('ongoing') : t('notStarted')}
          </Status>
        </List.Item.Description>
      </List.Item>
    </ScheduleDayLink>
  )
})

export const ActiveBookings = observer(
  (): JSX.Element => {
    const { t } = useTranslation('dashboard')
    const { bookings: bookingStore, stations, workers, ui } = useStores()

    const bookings = bookingStore.ongoing(ui.selectedStation?.id)
    const isLoading = stations.isLoading || workers.isLoading

    return (
      <DashboardItem>
        <DashboardItemCardHeader>
          <DashboardItemTitle>{t('activeBookings')}</DashboardItemTitle>
        </DashboardItemCardHeader>
        {isLoading ? (
          <>
            <Loading />
            <NumberOfBookingsInInbox>
              <Skeleton width="large" />
            </NumberOfBookingsInInbox>
          </>
        ) : bookings.length > 0 ? (
          <InboxList>
            {bookings.map(booking => (
              <BookingItem key={booking.id} booking={booking} />
            ))}
          </InboxList>
        ) : (
          <EmptyState icon={<FontAwesomeIcon icon={faSurprise} />} title={t('noActiveBookings')} />
        )}
      </DashboardItem>
    )
  },
)
