import { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { List } from 'components'
import { useStores } from 'models'
import { IComment, ICommentFilterParams } from 'models/comments'

import { StyledCommentList, StyledDeleteButton } from './comments.style'

interface ICommentListProps extends ICommentFilterParams {
  className?: string
}

export const CommentItem = observer(function CommentItem({ comment }: { comment: IComment }) {
  const { comments } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const onClickDelete = useCallback(async () => {
    setIsLoading(true)
    try {
      await comments.deleteComment(comment.id)
    } catch {
      setIsLoading(false)
    }
  }, [comment.id, comments])
  return (
    <List.Item>
      <List.Item.Description>{comment.content}</List.Item.Description>
      <List.Item.Right>
        <StyledDeleteButton
          disabled={isLoading}
          icon={
            isLoading ? <FontAwesomeIcon spin icon="spinner" size="xs" /> : <FontAwesomeIcon icon="trash" size="xs" />
          }
          size="small"
          type="button"
          onClick={onClickDelete}
        />
      </List.Item.Right>
    </List.Item>
  )
})

export const CommentList = observer(function CommentList(props: ICommentListProps) {
  const { ui, comments: commentStore } = useStores()
  const comments = commentStore.filterComments({
    connections: props.connections,
    start: props.start,
    end: props.end,
    tags: props.tags,
    stationId: parseInt(ui.selectedStation?.id ?? '0'),
  })

  if (comments.length === 0) return null
  return (
    <StyledCommentList className={props.className}>
      {comments.map(c => (
        <CommentItem key={c.id} comment={c} />
      ))}
    </StyledCommentList>
  )
})
