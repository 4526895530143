import { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { reaction } from 'mobx'

import { useStores } from 'models'
import { PATHS } from 'pages'
import { useRouter } from 'utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PublicRoute = ({ component: Component, ...rest }): JSX.Element => {
  const { push } = useRouter()
  const { userStore } = useStores()

  useEffect(
    () => {
      return reaction(
        () => userStore.isAuthenticated,
        isAuthenticated => {
          if (isAuthenticated) push(PATHS.dashboard.path)
        },
        { fireImmediately: true },
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return <Route {...rest} component={Component} />
}

export default PublicRoute
