import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { faMeh } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { EmptyState, List, Skeleton } from 'components'
import { BookingIcon } from 'components/booking-icon'
import { IBooking, useStores } from 'models'
import { PATHS } from 'pages'
import { formatShortISO, formatShortTime } from 'utils'

import {
  CompletedWrapper,
  DashboardItem,
  DashboardItemCardHeader,
  DashboardItemTitle,
  InboxList,
  NumberOfBookingsInInbox,
  ScheduleDayLink,
  Status,
  StyledDate,
} from './dashboard.styles'
import { Loading } from './loading'

/**
 * The number of bookings to show in the inbox on Dashboard
 *
 * NOTE: This could be a setting in future versions
 */
const bookingsToShow = 5

const BookingItem = observer(({ booking }: { booking: IBooking }) => {
  const { t } = useTranslation('dashboard')
  const path = generatePath(PATHS.schedule.path, { date: formatShortISO(booking.startTime || new Date(Date.now())) })

  return (
    <ScheduleDayLink to={path}>
      <List.Item>
        <List.Item.Left>
          <CompletedWrapper>
            <BookingIcon size="2x" />
          </CompletedWrapper>
        </List.Item.Left>
        <List.Item.Title>
          <CompletedWrapper>
            <Status
              isDelayed={!!booking.isDelayed}
              workFinished={!!booking.workFinished}
              workStarted={!!booking.workStarted}
            >
              {t('completed')}:&nbsp;
              {formatShortTime(booking.workFinished)}
            </Status>
            {booking.activeBookingModuleTitleExecutor}
          </CompletedWrapper>
        </List.Item.Title>
        <List.Item.Description>
          {booking.plateNumber}
          <StyledDate>
            {t('started')}&nbsp;
            {formatShortTime(booking.workStarted)}
          </StyledDate>
        </List.Item.Description>
      </List.Item>
    </ScheduleDayLink>
  )
})

export const CompletedBookings = observer(
  (): JSX.Element => {
    const { t } = useTranslation('dashboard')
    const { bookings, stations, workers } = useStores()

    const numberOfBookings = bookings.completedToday.length > bookingsToShow || bookings.completedToday.length
    const totalBookings = bookings.completedToday.length
    const isLoading = stations.isLoading || workers.isLoading

    return (
      <DashboardItem>
        <DashboardItemCardHeader>
          <DashboardItemTitle>{t('completedBookings')}</DashboardItemTitle>
        </DashboardItemCardHeader>
        {isLoading ? (
          <>
            <Loading />
            <NumberOfBookingsInInbox>
              <Skeleton width="large" />
            </NumberOfBookingsInInbox>
          </>
        ) : totalBookings > 0 ? (
          <>
            <InboxList>
              {bookings.completedToday
                .sort((a, b) => (b.workFinished?.getTime() ?? 0) - (a.workFinished?.getTime() ?? 0))
                .slice(0, bookingsToShow)
                .map(booking => {
                  return <BookingItem key={booking.id} booking={booking} />
                })}
            </InboxList>
            <NumberOfBookingsInInbox>
              {t('showingNumberOfCompletedBookings', {
                numberOfBookings,
                totalBookings,
              })}
            </NumberOfBookingsInInbox>
          </>
        ) : (
          <EmptyState icon={<FontAwesomeIcon icon={faMeh} />} title={t('noCompletedBookings')} />
        )}
      </DashboardItem>
    )
  },
)
