import * as yup from 'yup'

import { minutesFromMidnight } from 'utils'

declare module 'yup' {
  interface DateSchema {
    early: (time: number, message: string) => yup.DateSchema
    late: (time: number, message: string) => yup.DateSchema
  }
}

yup.addMethod(yup.date, 'early', function (earlyTime: number, message: string) {
  return this.test('is-early', message, function (value?: Date) {
    if (value === undefined) return false
    return minutesFromMidnight(value) >= earlyTime
  })
})

yup.addMethod(yup.date, 'late', function (lateTime: number, message: string) {
  return this.test('is-late', message, function (value?: Date) {
    if (value === undefined) return false
    return minutesFromMidnight(value) <= lateTime
  })
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = ({ workShiftStart, workShiftEnd }) =>
  yup.object({
    assignees: yup.array(yup.string().required().ensure()),
    // TODO: Enable line below when adding support for changing station
    // station: yup.string().required(),
    start: yup.date().early(workShiftStart, 'Too early').late(workShiftEnd, 'Too late').required('Required').defined(),
    status: yup.number().nullable(),
    end: yup
      .date()
      .early(workShiftStart, 'Too early')
      .late(workShiftEnd, 'too late')
      .when('start', (start, schema) => schema.min(start))
      .required('Required'),
  })
