/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css, DefaultTheme } from 'styled-components/macro'

import { DialogBox } from 'components/dialogbox'
import { Footer, StyledDialogBox } from 'components/dialogbox/dialogbox.styles'
import { Grid } from 'components/layout/grid'

export const StyledBookingModal = styled(DialogBox)`
  ${StyledDialogBox} {
    padding: 1rem 0;
  }

  ${Footer} {
    padding: 0 1rem;
  }
`

export const SectionHeader = styled.div`
  display: flex;

  svg {
    margin-right: 0.5rem;
  }
`

export const AttributeGrid = styled(Grid)`
  gap: 0;
`
export const AttributeName = styled.div`
  font-weight: bold;
`

export const AttributeValue = styled.div``

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`

export const StepConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const stepColor = (theme: DefaultTheme, delayed: boolean, finished: boolean, ongoing: boolean) => {
  if (delayed) return theme.colors.COLOR_DANGER_DEFAULT
  if (ongoing) return theme.colors.COLOR_WARNING_DEFAULT
  if (finished) return theme.colors.COLOR_SUCCESS_DEFAULT

  return theme.colors.COLOR_PRIMARY_DEFAULT
}

interface IStepProps {
  active: boolean
  delayed: boolean
  ongoing: boolean
  finished: boolean
  clickable: boolean
}
export const Step = styled.div<IStepProps>`
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;
  transition: background 0.3s;

  ${({ clickable, theme }) =>
    clickable &&
    css`
      pointer-events: auto;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        background: ${theme.colors.BACKGROUND_BASIC_COLOR_4};
      }
    `}

  ${({ active, delayed, ongoing, finished, theme }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.TEXT_CONTROL_COLOR};
      background: ${stepColor(theme, delayed, finished, ongoing)};
    `}
`

export const StepDivider = styled.div`
  flex-grow: 1;
  border-top: 1px solid black;
  margin: 3px;

  &:last-child {
    display: none;
  }
`
