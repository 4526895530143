import { getRoot, Instance, types } from 'mobx-state-tree'

import { IBooking } from 'models/bookingStore'
import { IRootStore } from 'models/rootStore'
import { IWorker } from 'models/workerStore'

export const Assignment = types
  .model({
    workerId: types.string,
    bookingId: types.string,
    isShadowBooking: types.boolean,
  })
  .views(self => ({
    get Booking(): IBooking {
      const { bookings } = getRoot<IRootStore>(self)

      return bookings.get(self.bookingId)
    },
    get Worker(): IWorker {
      const { workers } = getRoot<IRootStore>(self)

      return workers.get(self.workerId)
    },
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignment extends Instance<typeof Assignment> {}

export const AssignmentsStore = types
  .model({
    connections: types.array(Assignment),
  })
  .views(self => ({
    ByBookingId: (bookingId: string) => self.connections.filter(bw => bw.bookingId === bookingId),
    ByWorkerId: (workerId: string) => self.connections.filter(bw => bw.workerId === workerId),
    GetUnique: (workerId: string, bookingId: string): IAssignment | undefined => {
      const bws = self.connections.filter(bw => bw.workerId === workerId).filter(bw => bw.bookingId === bookingId)

      if (bws.length === 0) return undefined

      if (bws.length > 1) {
        // FIXME: What to do here? We have duplicates, should we clean up maybe?
        return bws[0]
      }

      return bws[0]
    },
    WorkerIsAssignedTo: (workerId: string, bookingId: string): boolean => {
      return (
        self.connections.filter(bw => bw.workerId === workerId).filter(bw => bw.bookingId === bookingId).length !== 0
      )
    },
  }))
  .actions(self => {
    function isAssignmentAdded(assignment: IAssignment): boolean {
      const result = self.connections.find(
        bw => bw.workerId === assignment.workerId && bw.bookingId === assignment.bookingId,
      )

      return !!result
    }

    function Assign(assignment: IAssignment): void {
      if (isAssignmentAdded(assignment)) return

      self.connections.push(assignment)
    }

    return {
      Assign,
      AssignMultiple: (assignments: IAssignment[]): void => {
        assignments.forEach(assignment => {
          Assign(assignment)
        })
      },
      RemoveBookingAssignments: (bookingId: string): void => {
        const newArr = self.connections.filter(bw => bw.bookingId !== bookingId)

        self.connections.replace(newArr)
      },
      RemoveWorkerAssignments: (workerId: string): void => {
        const newArr = self.connections.filter(bw => bw.workerId !== workerId)

        self.connections.replace(newArr)
      },
      RemoveAssignment: (bookingId: string, workerId: string): void => {
        const newArr = self.connections.filter(bw => bw.bookingId !== bookingId || bw.workerId !== workerId)

        self.connections.replace(newArr)
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentsStore extends Instance<typeof AssignmentsStore> {}
