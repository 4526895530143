import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfDay, startOfDay } from 'date-fns'

import { Page } from 'components/layout/page'
import { SecondaryControls } from 'components/secondary-controls'
import { useStores } from 'models'

import { ActiveBookings } from './activebookings'
import { CompletedBookings } from './completedbookings'
import { DashboardLayout } from './dashboard.styles'
import { DelayedBookings } from './delayedbookings'
import { UnassignedBookings } from './unassignedbookings'

export function DashboardPage(): JSX.Element {
  const { t } = useTranslation('dashboard')
  const { bookings } = useStores()

  useEffect(() => {
    const startDate = startOfDay(new Date())
    const endDate = endOfDay(new Date())

    bookings.updateBookingsFromServer(startDate, endDate, {
      getDelayed: true,
      getInbox: true,
      getOngoing: true,
    })
  }, [bookings])

  return (
    <Page secondaryControlsComponent={<SecondaryControls />} title={t('dashboard')}>
      <DashboardLayout>
        <UnassignedBookings />
        <ActiveBookings />
        <DelayedBookings />
        <CompletedBookings />
      </DashboardLayout>
    </Page>
  )
}
