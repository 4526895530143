// import 'utils/wdyr'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from 'serviceWorker'

import { App } from 'app'
import { sentryInitSettings } from 'config/sentry'

Sentry.init(sentryInitSettings)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
