/* eslint-disable react/prop-types */

import { IThemedComponent } from 'components/theming'

import { IButtonProps } from './button.interfaces'
import { StyledButton } from './button.styles'
import { buttonTheme } from './button.theme'

export const Button: React.FC<IButtonProps<HTMLButtonElement>> & IThemedComponent = ({
  children,
  className,
  appearance = 'filled',
  disabled,
  fullWidth = false,
  onClick,
  pulse,
  type = 'button',
  shape = 'rectangle',
  size = 'medium',
  status = 'basic',
}): JSX.Element => {
  return (
    <StyledButton
      appearance={appearance}
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      pulse={appearance === 'hero' && !!pulse === true}
      role="button"
      shape={shape}
      size={size}
      status={status}
      type={type}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

Button.defaultTheme = buttonTheme
