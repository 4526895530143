import { useTranslation } from 'react-i18next'

import { Inbox } from 'components/inbox'
import { Sidebar, SidebarModule } from 'components/layout/sidebar'
import { ScheduleCalendar } from 'components/schedule-calendar'

import { IScheduleSidebarProps } from './schedule-sidebar.interfaces'

export const ScheduleSidebar = ({ onSelectDate, selectedDate }: IScheduleSidebarProps): JSX.Element => {
  const { t } = useTranslation('schedule')
  const { t: tc } = useTranslation()

  return (
    <Sidebar>
      <SidebarModule contentPadding="none" header={tc('common:date')}>
        <ScheduleCalendar selectedDate={selectedDate} onSelectDate={onSelectDate} />
      </SidebarModule>
      <SidebarModule contentPadding="none" header={t('inbox')}>
        <Inbox />
      </SidebarModule>
    </Sidebar>
  )
}
