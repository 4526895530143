/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components/macro'

import { EmptyState } from 'components/empty-state'
import { StyledIcon, StyledTitle } from 'components/empty-state/empty-state.styles'
import { StyledList } from 'components/list/list/list.styles'
import { StyledListItem } from 'components/list/listitem/listitem.styles'
import { SSkeletonLine } from 'components/skeleton/skeleton.styles'

export const StyledInbox = styled.div<{ isOverInbox: boolean }>`
  transition: all 200ms ease-out;
  width: 100%;
`

export const InboxList = styled(StyledList)`
  ${StyledListItem} {
    cursor: pointer;
    padding: 0 1rem 0.5rem;
    transition: background 350ms cubic-bezier(0.5, 1, 0.5, 1);
    border-width: 1px;
    border-color: transparent;
    border-style: solid;

    &:hover {
      background-color: ${({ theme }) => theme.colors.COLOR_BASIC_HOVER};
      border-color: ${({ theme }) => theme.colors.COLOR_BASIC_HOVER_BORDER};
    }
  }
`

export const OtherStationsInbox = styled.p`
  padding: 0.5rem 1rem;
  margin: 0;
  font-size: 0.875rem;

  border-top: 1px solid ${({ theme }) => theme.support.DIVIDER_COLOR};
`

export const NoBooking = styled(EmptyState)`
  ${StyledIcon} {
    --fa-secondary-color: ${({ theme }) => theme.baseColors.COLOR_SUCCESS_800};
    --fa-primary-color: ${({ theme }) => theme.baseColors.COLOR_BASIC_100};
  }

  ${StyledTitle} {
    color: ${({ theme }) => theme.baseColors.COLOR_BASIC_700};
  }
`

export const SkeletonInbox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

export const SkeletonBooking = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-left: 0.5rem;

    ${SSkeletonLine} {
      margin-bottom: 0.5rem;
    }
  }
`

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;
`

export const InboxGroupHeader = styled.div`
  background: ${({ theme }) => theme.colors.BORDER_BASIC_COLOR_4};
  color: black;
  text-align: center;
  font-weight: bold;
  padding: 0.1rem 0;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
`

export const DropToInbox = styled.div<{ isOverInbox: boolean }>`
  position: absolute;

  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  display: flex;
  visibility: ${({ isOverInbox }) => (isOverInbox ? 'inherit' : 'hidden')};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: ${({ isOverInbox }) => (isOverInbox ? 1 : 0)};

  background: white;
  border: 1px solid ${({ theme }) => theme.colors.COLOR_SUCCESS_TRANSPARENT_HOVER_BORDER};

  transition: all 200ms ease-out;

  --fa-primary-color: ${({ theme }) => theme.colors.TEXT_PRIMARY_HOVER_COLOR};
  --fa-secondary-color: ${({ theme }) => theme.colors.COLOR_DANGER_DEFAULT};
`
