import { ApiConfig } from '@cdab/cplan-api-client'

import { getNumberFromEnv, getStringFromEnv } from './environment'

export const apiConfig: ApiConfig = {
  apiUrl: getStringFromEnv('API_URL'),
  baseOptions: {
    timeout: getNumberFromEnv('API_TIMEOUT', 10) * 1000,
  },
}
