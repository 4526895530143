import styled, { css, DefaultTheme, keyframes } from 'styled-components/macro'

import { IStyledTaskPositionProps, IStyledTaskProps } from './scheduledtask.interfaces'

const scroll = keyframes`
  from {
    background-position: 0;
  }

  to {
    background-position: 2500px;
  }
`

const calcTop = (lane: number) => 2 + lane * 4

/**
 * Calculate width of task
 * @param left - left position
 * @param right - right position
 * @param containerWidth - width of container
 */
const calcWidth = (left: number, right: number, containerWidth: number) => {
  left = left < 0 ? 0 : left
  right = right > containerWidth ? containerWidth : right
  return right - left
}

const taskColor = (theme: DefaultTheme, { isDelayed, isFinished, isOngoing, isShadowTask }: IStyledTaskProps) => {
  if (isShadowTask) {
    return theme.colors.COLOR_CONTROL_DISABLED
  }

  if (isDelayed) {
    return theme.colors.COLOR_DANGER_DEFAULT
  }

  if (isFinished) {
    return theme.colors.COLOR_SUCCESS_DEFAULT
  }

  if (isOngoing) {
    return theme.colors.COLOR_WARNING_DEFAULT
  }

  return theme.colors.COLOR_PRIMARY_DEFAULT
}

export const StyledTaskBase = styled.div<IStyledTaskProps>`
  transition: opacity 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  position: relative;

  border: 1px solid transparent;

  max-height: 3.5rem;
  padding: 0.5rem;

  cursor: pointer;

  overflow: visible;
  background: ${({ theme, ...props }) => taskColor(theme, props)};

  color: ${({ theme }) => theme.colors.TEXT_CONTROL_COLOR};
  border-radius: 0.5rem;

  /* Hide while dragging */
  opacity: ${props => (props.isDragging ? 0.1 : 1)};

  &:hover {
    opacity: ${props => (props.isDragging ? 0.1 : 0.8)};
  }
`

export const TaskInLane = styled.div.attrs<IStyledTaskPositionProps>(props => ({
  style: {
    top: calcTop(props.lane) + 'rem',
    left: Math.max(0, props.left),
    width: calcWidth(props.left, props.right, props.maxWidth),
  },
}))<IStyledTaskPositionProps>`
  position: absolute;

  /* Show marker if a task is starts/end outside worktime */
  &::before,
  &::after {
    content: ' ';
    overflow: hidden;
    display: block;
    position: absolute;
    height: 100%;
    max-width: 45px;
    width: 50%;
    top: 0;
  }
  ${props =>
    props.left <= 0 &&
    css`
      &::before {
        left: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    `}
  ${props =>
    props.right >= props.maxWidth &&
    css`
      &::after {
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    `}
`

export const StyledDayTask = styled.div`
  width: 16rem;
`

export const TaskTitle = styled.p`
  text-transform: uppercase;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.TEXT_CONTROL_COLOR};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  line-height: initial;
`

export const LoadingOverlay = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};

  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: repeating-linear-gradient(111deg, #777, #fff 1px 8px, #777 11px 21px);
  animation: ${scroll} 30s linear infinite;
`

export const TaskStartTime = styled.p`
  color: ${({ theme }) => theme.colors.TEXT_CONTROL_COLOR};
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  line-height: initial;
`

export const InfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`

export const Badges = styled.div`
  display: flex;
  align-items: flex-end;
`
export const BadgeCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 0.1rem;

  background: white;

  font-size: 0.7rem;
  font-weight: bold;
  height: 1.2rem;
  border-radius: 0.6rem;
  padding: 0 0.4rem;
`

export const BadgeText = styled.span`
  color: black;
`

export const StyledResizeHandle = styled.div`
  position: absolute;
  right: -1px;
  top: 0;
  cursor: ew-resize;
  width: 50%;
  max-width: 8px;
  height: 100%;
`
