import { startOfWeek } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { EmptyState, Module } from 'components'
import { Card } from 'components/card'
import { useStores } from 'models'
import { IBooking } from 'models/bookingStore/booking'
import { getLocaleFile } from 'utils'

import { Weekdays } from './schedule-week.styles'

const Column = ({ bookings }: { bookings: IBooking[] }): JSX.Element => {
  const book = bookings.map(booking => (
    <Card key={booking.id.toString()} spacing="small">
      <p>Title: {booking.bookingTitle}</p>
      <p>Description: {booking.description}</p>
      <p>Status: {booking.status?.name}</p>
    </Card>
  ))

  if (book.length > 0) {
    return <>{book}</>
  } else {
    return <EmptyState title="Inga bokningar" />
  }
}

const DAYS_TO_SHOW = 7

export const ScheduleWeek = observer(function ScheduleWeek({ selectedDate }: { selectedDate: Date }) {
  const { bookings, ui } = useStores()

  const startDate = startOfWeek(new Date(selectedDate), { locale: getLocaleFile() })

  const endDate = new Date()
  endDate.setDate(startDate.getDate() + DAYS_TO_SHOW - 1)

  const currentBookings = bookings.filtered({
    start: startDate,
    end: endDate,
    stationIds: ui.selectedStation && [ui.selectedStation.id],
  })

  const mondayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate())
  const tuesdayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate() + 1)
  const wednesdayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate() + 2)
  const thursdayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate() + 3)
  const fridayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate() + 4)
  const saturdayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate() + 5)
  const sundayBookings = currentBookings.filter(booking => booking.startTime?.getDate() === startDate.getDate() + 6)

  // kör en loop DAYS_TO_SHOW gånger med start på startDate
  // Hämta ut de bokningar som finns
  // gå vidare till nästa dag och hämta ut de bokningarna och så vidare

  return (
    <Weekdays columns={7}>
      <Module key={1} columns={1} title="Måndag">
        <Column bookings={mondayBookings} />
      </Module>
      <Module key={2} columns={1} title="Tisdag">
        <Column bookings={tuesdayBookings} />
      </Module>
      <Module key={3} columns={1} title="Onsdag">
        <Column bookings={wednesdayBookings} />
      </Module>
      <Module key={4} columns={1} title="Torsdag">
        <Column bookings={thursdayBookings} />
      </Module>
      <Module key={5} columns={1} title="Fredag">
        <Column bookings={fridayBookings} />
      </Module>
      <Module key={6} columns={1} title="Lördag">
        <Column bookings={saturdayBookings} />
      </Module>
      <Module key={7} columns={1} title="Söndag">
        <Column bookings={sundayBookings} />
      </Module>
    </Weekdays>
  )
})
