import { BOOKING_DND_TYPE, IDragInfo, INBOX_DND_TYPE } from 'constants/dragtypes'

import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { observer } from 'mobx-react-lite'

import { DayTask } from 'components/draggable-task/daytask'
import { useStores } from 'models'

import { BookingContainer, DayScheduleTitle, StyledBooking, StyledDaySchedule } from './dayschedule.styles'

function Booking({ id }) {
  return <StyledBooking>Booking with # {id}</StyledBooking>
}

export const DaySchedule = observer(function DaySchedule(): JSX.Element {
  const { bookings } = useStores()
  const [bookingsThisDay, setBookingsThisDay] = useState<string[]>([])
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: [BOOKING_DND_TYPE, INBOX_DND_TYPE],
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      }
    },
    drop(item: IDragInfo, monitor) {
      console.log('Drop item', item)
      setBookingsThisDay(b => (b.includes(item.id) ? b : [...b, item.id]))
    },
  }))

  const bookingItems = bookingsThisDay.map(bookingId => bookings.get(bookingId))
  return (
    <StyledDaySchedule>
      <DayScheduleTitle>Whole day schedle</DayScheduleTitle>
      <BookingContainer ref={dropRef} isOver={isOver}>
        {bookingItems.map(booking => (
          <DayTask key={booking.id} booking={booking} />
        ))}
      </BookingContainer>
    </StyledDaySchedule>
  )
})
