import { BOOKING_DND_TYPE } from 'constants/dragtypes'

import { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { IBooking, IWorker } from 'models'

export function useDragBooking({ booking, worker }: { booking: IBooking; worker?: IWorker }, enableDrag: boolean) {
  const [{ isDragging }, dragRef, preview] = useDrag({
    type: BOOKING_DND_TYPE,
    item: () => {
      booking.lock()
      return {
        id: booking.id,
        sourceWorkerId: worker?.id,
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => enableDrag && !booking.isLoading && !booking.isCompleted,
    end: () => {
      booking.unlock()
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return {
    isDragging,
    dragRef,
  }
}
