import { format, formatISO, startOfDay } from 'date-fns'

export const formatShortISO = (date: Date): string => formatISO(date, { representation: 'date' })

export const formatShortTime = (date?: Date | null, empty = ''): string => (date ? format(date, 'HH:mm') : empty)

export const formatDateTime = (date?: Date, empty = ''): string => (date ? format(date, `yyyy-MM-dd'T'HH:mm`) : empty)

/**
 * format a period in minutes to "HH:MM"
 * @remarks 90min -> 01:30
 * @param lengthInMinutes - period in minutes
 */
export const formatPeriod = (lengthInMinutes: number): string => {
  const h = String(Math.floor(lengthInMinutes / 60))
  const m = String(lengthInMinutes % 60)
  return `${h.padStart(2, '0')}:${m.padStart(2, '0')}`
}

export const parsePeriod = (period: string): number => {
  const [h, m] = period.split(':')
  return parseInt(h) * 60 + parseInt(m)
}

export const tzOffset = (date: Date, timezone?: string): number => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const localeDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }))

  return (localeDate.getTime() - utcDate.getTime()) / 60000
}

/**
 * Calculate how many minutes from midnight a time for given date has
 * @param d - Date to convert
 */
export function minutesFromMidnight(d: Date, options?: { timezone?: string; origin?: Date }): number {
  const { origin, timezone } = options || {}
  const offset = timezone ? tzOffset(d) - tzOffset(d, timezone) : 0
  return (d.getTime() - startOfDay(origin || d).getTime()) / 60000 - offset
}
