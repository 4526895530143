import { BOOKING_DND_TYPE, IDragInfo, INBOX_DND_TYPE } from 'constants/dragtypes'

import { Fragment, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { faInfoCircle, faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { Observer, observer } from 'mobx-react-lite'

import { BookingIcon } from 'components/booking-icon'
import { List } from 'components/list/list'
import { Skeleton } from 'components/skeleton'
import { IBooking, useStores } from 'models'

import {
  DropToInbox,
  InboxGroupHeader,
  InboxList,
  NoBooking,
  OtherStationsInbox,
  SkeletonBooking,
  SkeletonInbox,
  StyledInbox,
} from './inbox.styles'

interface IInboxBookingProps {
  booking: IBooking
}

const InboxBooking = ({ booking }: IInboxBookingProps): JSX.Element => {
  const [, dragRef, preview] = useDrag({
    type: INBOX_DND_TYPE,
    item: { id: booking.id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return (
    <Observer>
      {(): JSX.Element => (
        <List.Item ref={dragRef}>
          {/* TODO: Lägg till styles korrekt, förslagsvis med opacity 0.4 när isDragging is true */}
          <List.Item.Left>
            <BookingIcon size="2x" />
          </List.Item.Left>

          <List.Item.Title>{booking.inboxTitle}</List.Item.Title>
          <List.Item.Description>{booking.description}</List.Item.Description>
        </List.Item>
      )}
    </Observer>
  )
}

const LoadingInbox = (): JSX.Element => (
  <SkeletonInbox>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
  </SkeletonInbox>
)

export const Inbox = observer(
  (): JSX.Element => {
    const { bookings, stations, workers, ui } = useStores()
    const { t } = useTranslation('schedule')

    const atCurrentStation = bookings.inbox(ui.selectedStation && [ui.selectedStation.id])
    const atOtherStations = bookings.inbox().filter(b => b.stationId !== ui.selectedStation?.id)
    const numberAtOtherStations = atOtherStations.length
    const [{ isOverInbox }, dropRef] = useDrop({
      accept: BOOKING_DND_TYPE,
      drop(item: IDragInfo) {
        bookings.moveBookingToInbox(item.id)
      },
      collect: monitor => ({
        isOverInbox: monitor.isOver(),
      }),
    })

    const groupedBookings = _.groupBy(atCurrentStation, 'status.name')
    const groupKeys = _.keys(groupedBookings).sort((a, b) => {
      if (a === 'undefined') return -1
      if (b === 'undefined') return 1

      return ('' + a).localeCompare(b)
    })

    return (
      <StyledInbox isOverInbox={isOverInbox}>
        {stations.isLoading || workers.isLoading ? (
          <LoadingInbox />
        ) : (
          <div ref={dropRef}>
            <DropToInbox isOverInbox={isOverInbox}>
              <FontAwesomeIcon icon={faLayerPlus} size="2x" />
              <p>Move to inbox</p>
            </DropToInbox>
            {atCurrentStation.length === 0 ? (
              <NoBooking icon={<FontAwesomeIcon icon={faInfoCircle} />} title={t('noBookings')} />
            ) : (
              <InboxList>
                {groupKeys.map(groupKey => {
                  return (
                    <Fragment key={groupKey}>
                      {groupKey !== 'undefined' ? (
                        <InboxGroupHeader>
                          {groupKey} ({groupedBookings[groupKey].length})
                        </InboxGroupHeader>
                      ) : null}
                      {groupedBookings[groupKey].map(booking => (
                        <InboxBooking key={booking.id} booking={booking} />
                      ))}
                    </Fragment>
                  )
                })}
              </InboxList>
            )}
            <OtherStationsInbox>{t('otherStationsInbox', { numberAtOtherStations })}</OtherStationsInbox>
          </div>
        )}
      </StyledInbox>
    )
  },
)
