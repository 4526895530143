import { TCommonTheme, TThemeFromFn, tuple } from 'components/theming'

export const MultiSelectTheme = (theme: TCommonTheme) =>
  tuple([
    'MultiSelect',
    {
      background: theme.colors.COLOR_BASIC_DEFAULT,
      add: {
        backgroundColor: theme.colors.COLOR_BASIC_DEFAULT,
        hoverBackgroundColor: theme.colors.COLOR_BASIC_HOVER,
        hoverBorderColor: theme.colors.COLOR_BASIC_HOVER_BORDER,
        textColor: theme.colors.TEXT_BASIC_COLOR,
      },
      chip: {
        background: theme.colors.COLOR_BASIC_DEFAULT,
        color: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
      },
    },
  ])

export type TMultiSelectTheme = TThemeFromFn<typeof MultiSelectTheme>
